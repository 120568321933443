@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

/* // */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #222222;
  --first-color-light: #848484;
  --white-color: #ffffff;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --blue-color: #0f70cd;
  --lblue-color: #f2fafc;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html,
body {
  /* min-height: 100%; */
  /* height: 100vh; */
}

body {
  font-family: "Roboto", sans-serif;
  color: #333333 !important;
  position: relative;
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

.content-body {
  height: calc(100vh - 83px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: #ffffff;
  z-index: var(--z-fixed);
  transition: 0.5s;
}

body.login-bg {
  padding: 0 !important;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.breadcrumb {
  font-size: 14px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--white-color);
  padding: 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
  /* box-shadow: 0 0px 10px #333333; */
}

.navinstitute {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 1rem;
  padding-left: 1rem;
  /* background-color: #e3e3e3; */
}

.nav_logo-icon {
  /* background: url("../images/logo.png") no-repeat; */
  background: url("../images/iamsamarth-Logo-Final.svg") no-repeat;

  display: block;
  width: 121px;
  height: 48px;
  background-size: 100%;
  transition: 0.5s;
}

.body-pd .nav_logo-icon {
  background: url("../images/logo-small.png") no-repeat;
  display: block;
  width: 32px;
  height: 32px;
  background-size: 100%;
  transition: 0.5s;
}

.nav_logo-name {
  color: var(--first-color);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  transition: 0.3s;
}

.nav_link:hover {
  color: var(--blue-color);
}

.nav-icon {
  font-size: 1.25rem;
  width: 18px;
  height: 18px;
  display: inline-block;
}

.nav-icon.dashboard-icon {
  background: url("../images/dashboard-icon.svg") no-repeat;
}

.nav-icon.profile-icon {
  background: url("../images/profile-icon.svg") no-repeat;
}

.nav-icon.settings-icon {
  background: url("../images/settings-icon.svg") no-repeat;
}

.nav-icon.report-icon {
  background: url("../images/report-icon.svg") no-repeat;
}

.nav-icon.manage-icon {
  background: url("../images/manage-icon.svg") no-repeat;
}

.nav-icon.feedback-icon {
  background: url("../images/feedback-icon.svg") no-repeat;
}

.body-pd .nav-icon {
  padding-right: 0.5rem;
  transition: 0.5s;
}

.l-navbar.show {
  left: 0;
}

.active {
  color: var(--blue-color);
}

.height-100 {
  height: 100vh;
}

a {
  text-decoration: none;
}

.text-dgray {
  color: #333333;
}

.text-blue {
  color: #0f70cd;
}

.text-green {
  color: #39b54a;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875em;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f24 {
  font-size: 1.5rem;
}

/* .form-label { font-size: 14px;} */
.bg-lblue {
  background-color: var(--lblue-color);
}

/* .rounded-12{border-radius: 0.5rem;} */
.rounded-16 {
  border-radius: 1rem;
}

.border-dark {
  border-color: #333333 !important;
}

.border-gray {
  border-color: #29abe2 !important;
}

.border-lgray {
  border-color: #cccccc !important;
}

.btn-primary {
  background-color: #0f70cd;
  border-color: #29abe2;
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: #045da5;
  border-color: #29abe2;
}

.border-primary {
  border-color: #0f70cd !important;
}

.input-group .btn {
  border-color: #045da5;
}

.forgot-color {
  color: #0071bc;
  text-decoration: none;
}

.login-bg {
  background: url("../images/login-bg.png") repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

.login-block-wraper {
  min-height: calc(100vh - 50px);
  padding: 0 1rem;
}

.login-block,
.signup-block {
  box-shadow: 0px 1px 12px #575656;
}

.login-logo {
  width: 170px;
}

.login-desc {
  color: #333333;
}

.login-hd {
  font-size: 1.375rem;
  font-weight: 500;
}

.login-fileds {
  border-top: 1px solid #b3b3b3;
  margin-top: 1rem;
}

.login-fileds .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: 1.125rem;
}

.login-fileds .form-floating > .form-control:focus {
  outline: none !important;
}

.login-fileds .form-control:focus {
  box-shadow: none;
}

.login-fileds .form-floating > label {
  font-size: 18px;
  font-weight: 300;
}

.login-fileds .form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 2.5rem;
}

.login-elements {
  margin-top: 3rem;
}

.login-elements .login-elements-icons {
  width: 80%;
}

.signup-quot {
  font-size: 36px;
}

.inside-bg {
  background: url("../images/inside-bg.jpg") no-repeat bottom center;
  background-size: cover;
  background-attachment: fixed;
}

.navbar .navbar-toggler {
  border: none !important;
  padding: 0 !important;
  top: 1rem !important;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  background-color: #222222 !important;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav {
  margin: 0 10px;
}

.sidebar .nav-link {
  font-weight: 300;
  color: #f2f2f2;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active,
.sidebar .nav-link.active:focus,
.sidebar .nav-link.active:hover {
  color: #ffffff;
  background-color: #0f70cd;
}

.sidebar .nav-link:focus,
.sidebar .nav-link:hover {
  color: #f07d32;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navbar {
  background-color: #f2f2f2;
}

.navbar-brand {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  background-color: #f2f2f2 !important;
  text-align: center;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.15rem 1rem;
}

.navbar .form-control:focus {
  box-shadow: none;
}

.nav_name {
  transition: 0.5s;
  word-break: break-all;
  width: 120px;
}

.body-pd .nav_name {
  padding-left: 10px;
}

.inside-bg .l-navbar:hover .nav_name {
  padding-left: 0;
}

.logo {
  width: 145px;
}

.user-name {
  font-weight: 300;
  line-height: 16px;
  position: relative;
}

.user-name:after {
  position: absolute;
  content: "";
  right: 0;
  top: 5px;
  bottom: 5px;
  border-right: 2px solid #333333;
}

.logout-icon {
  background: url("../images/logout-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-indent: -9999px;
}

.logout-icon:hover,
.logout-icon:focus {
  background-position: 0 -21px;
  transition: 0.4s;
}

.main-title {
  margin-top: 80px;
}

.global-search {
  width: 100%;
}

.content-waper {
  padding-top: 1rem;
}

.breadcrumb-hd .breadcrumb-item,
.breadcrumb-hd .breadcrumb-item a {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-decoration: none;
}

.form-control,
.form-select {
  /* border: 1px solid #676767 */
}

.dkpi-block .dkpi-block-content {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  text-decoration: none;
  display: block;
  padding: 13px;
  color: #4d4d4d;
  position: relative;
  min-height: 141px;
}

.dkpi-block .dkpi-block-content:after {
  content: "";
  position: absolute;
  /* background: url("../images/arrow-icon.svg") no-repeat; */
  width: 29px;
  height: 21px;
  right: 12px;
  bottom: 10px;
  transition: right 0.5s;
}

.dkpi-block .dkpi-block-content.no-after:after {
  display: none;
}

.dkpi-block .dkpi-block-content:hover:after {
  right: 2px;
}

.dkpi-block .dkpi-block-content:hover {
  border-color: #29abe2;
  box-shadow: 1px 1px 3px #888888;
}

.dkpi-block .dkpi-block-content .dkpi-hd {
  font-size: 18px;
  font-weight: 500;
  color: #4d4d4d;
}

.dkpi-block .dkpi-block-content .dkpi-hd small {
  display: block;
  font-size: 12px;
  color: #808080;
  font-weight: 400;
}

.dkpi-block .dkpi-block-content .dkpi-hd-md {
  font-size: 14px;
}

.dkpi-block .dkpi-block-content p {
  color: #4d4d4d;
}

.dkpi-block-eicon {
  background: url("../images/edit-icon.svg") no-repeat;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 3px;
}

.dkpi-num {
  font-size: 52px;
}

.dkpi-date {
  font-size: 12px;
  font-weight: 300;
}

.dkpi-table {
  border: 1px solid #cccccc;
  text-align: center;
}

.dkpi-table,
.dkpi-table table {
  padding: 0;
  width: 100%;
}

.dkpi-table th,
.dkpi-table td {
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  background-color: transparent !important;
}

.student-icon {
  width: 34px;
  height: 46px;
}

.section-hd {
  font-size: 20px;
  color: #4d4d4d;
  font-weight: 500;
}

/*.content-body{min-height: calc(100vh - 180px);}*/
.steps {
  font-size: 1.35rem;
  font-weight: 300;
  font-style: italic;
}

.steps img {
  width: 24px;
  margin-right: 10px;
}

.steps strong {
  color: #0f70cd;
  font-weight: 900;
}

.survey-qution h2 {
  color: #0f70cd;
  font-weight: 300;
  font-size: 22px;
}

.survey-qution label {
  font-size: 18px;
  font-weight: 300;
}

.survey-qution label.qution {
  font-weight: 500;
}

.form-check-input:checked {
  background-color: #0f70cd;
  border-color: #0f70cd;
}

.form-check-input {
  border-width: 2px;
}

.gratejob-text {
  font-size: 72px;
  font-weight: 300;
  color: #00a99d;
}

.gratejob-text .success-icon {
  width: 72px;
}

.gratejob-textsub {
  font-size: 50px;
  font-weight: 300;
}

/* .form-label {
	font-weight: 500;
} */
.required-star {
  font-size: 20px;
  line-height: 20px;
  color: #ff0000;
  font-style: normal;
}

.error-msg {
  font-size: 12px;
  color: #ff0000;
  margin-top: 3px;
  margin-bottom: 0;
}

.label-mb {
  margin-top: 12px;
  margin-bottom: 12px;
}

.custom-form .required-star {
  font-weight: 700;
}

.custom-form .form-control:focus {
  box-shadow: none;
}

.custom-form select {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  background: #ffffff url(../images/select-arrow.svg) no-repeat center right
    10px;
  padding-right: 38px;
}

.custom-form select::-ms-expand {
  display: none;
}

.form-control,
.form-select {
  /* border: 1px solid #676767 */
}

.form-check-inline-custom .form-check-inline {
  min-width: 80px;
}

.form-check-inline-custom .form-check-label {
  font-size: 14px;
}

.file {
  position: relative;
  overflow: hidden;
}

.file input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.file-mt {
  margin-top: 32px;
}

.success-text {
  font-size: 3.75rem;
}

.rocket-img {
  width: 200px;
  margin-top: 100px;
  transform: rotate(30deg);
  margin-left: 50px;
}

.changepass-block {
  border: 2px solid #ccc;
  border-radius: 30px;
  padding-left: 4rem;
  padding-right: 4rem;
}

.form-footer {
  height: 82px;
  background-color: #ffffff;
}

.deatils-block .list li {
  font-size: 14px;
  margin-bottom: 15px;
}

.deatils-block .list li label {
  min-width: 180px;
  color: #000000;
}

.deatils-block .list li p {
  color: #4d4d4d;
}

.deatils-hd {
  color: #4d4d4d;
  font-size: 20px;
}

.profile-details label {
  color: #333333;
}

.profile-details .text {
  color: #4d4d4d;
  font-size: 22px;
  margin-top: -5px;
  font-weight: 500;
}

/* .modal-header{
	 background-color: #e3e3e3;
} */
.table_new_ui th {
  border: none !important;
}

.table_new_ui th {
  background-color: #cccccc !important;
}

.table_new_ui th,
.table_new_ui td {
  font-size: 12px;
  color: #333333;
  font-weight: 400;
}
.table_new_ui td {
  background-color: #ffffff !important;
}
.table_new_ui td {
  /* border: none; */
}
header.body-pd .nav_logo {
  display: none;
}

.body-pd .l-navbar:hover .nav_logo {
  padding-top: 8px;
  padding-bottom: 8px;
}
@media (max-width: 500px) {
  .pf_txt {
    left: 15% !important;
  }
}

.video_ck iframe {
  max-width: 100% !important;
  position: static !important;
  height: 40% !important;
  width: 50% !important;
  border-radius: 1.5%;
}
.video_ck figure div div {
  position: static !important;
  padding: 0% !important;
  height: 50% !important;
}

.img_ck p {
  margin-bottom: 0px;
}

.img_ck math {
  display: ruby !important;
  max-width: 100% !important;
}
.img_ck mstyle {
  display: ruby !important;
  max-width: 100% !important;
}
.img_ck mrow {
  display: ruby !important;
  max-width: 100% !important;
}
.img_ck_lg p {
  margin-bottom: 0px;
}
.img_ck_lg math {
  display: ruby;
  max-width: 100% !important;
}
.img_ck_lg mstyle {
  display: ruby;
  max-width: 100% !important;
}
.img_ck_lg mrow {
  display: ruby;
  max-width: 100% !important;
}
.vd_ck_width {
  width: 355px;
}
@media (min-width: 768px) {
  /*.login-block-wraper{ transform: translateY(-100px);}*/
  .ex_border {
    border: 2px solid #dae3f3;
  }
  .ab_custom_list {
    width: 334px;
  }
  .img_ck img {
    max-width: 100%;
  }
  .img_ck_lg img {
    max-width: 45%;
  }
  .img_ck_otp img {
    max-width: 13%;
  }

  body {
    padding-left: 230px;
  }

  .nav_logo {
    margin-bottom: 2rem;
  }

  .login-block-wraper {
    min-height: calc(100vh - 40px);
  }

  .login-block {
    width: 600px;
  }

  .login-fileds {
    border-left: 1px solid #b3b3b3;
    border-top: none;
    margin-top: 0;
  }

  .login-elements .login-elements-icons {
    width: 250px;
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 0 0 245px;
  }

  .l-navbar {
    left: 0;
    width: 224px;
  }

  .nav_logo,
  .body-pd .l-navbar:hover .nav_logo {
    padding-left: 1.5rem !important;
  }

  .body-pd .nav_logo {
    padding-left: 0.5rem;
    padding-left: 0.8rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 0px);
  }
  .nw_nav.show {
    margin: 0px 0px 0px 10px;
  }

  .mathematics.show {
    left: 0px;
    transition: 0.5s;
  }
  .moleculer.show {
    left: 0px;
    transition: 0.5s;
  }
  .l-navbar:hover .mathematics {
    left: 120px;
  }
  .l-navbar:hover .moleculer {
    left: 10px;
  }
  .l-navbar:hover {
    width: calc(var(--nav-width) + 156px) !important;
  }

  .l-navbar:hover .nav_logo-icon {
    background: url("../images/iamsamarth-Logo-Final.svg") no-repeat;
    display: block;
    width: 121px;
    height: 48px;
    background-size: 100%;
    transition: 0.5s;
  }

  .body-pd .l-navbar:hover .nav-icon {
    padding-right: 0;
    transition: 0.5s;
  }

  .body-pd .nav_logo-icon {
    width: 40px;
    height: 40px;
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 15px);
  }
  #q_timer_block {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
  }
}

@media (min-width: 992px) {
  .navbar {
    background-color: #f2f2f2;
  }
  .score-card-sticky {
    position: sticky;
    top: 160px;
  }

  .navbar-brand {
    position: relative;
  }

  .navbar-brand:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 10px;
    right: 10px;
    border-bottom: 1px solid #f4715c;
  }

  .sidebar,
  .navbar-brand {
    width: 250px;
  }

  .sidebar {
    padding: 70px 0 0;
  }

  .content-waper {
    padding-left: 265px;
    padding-top: 0;
  }

  .global-search {
    width: 450px;
  }

  .signup-quotblock {
    border-right: 1px solid #cccccc;
  }

  .changepass-row {
    min-height: calc(100vh - 220px);
  }

  /*.content-body{overflow-y: auto; overflow-x: hidden;}*/
  .deatils-block .list li {
    margin-bottom: 5px;
  }
}

@media (min-width: 1100px) and(max-width:1200px) {
  .event_hall_ticket_scroll {
    width: 100% !important;
    overflow-x: auto !important;
  }
  .maths_time_card {
    width: 167px;
  }
}

@media (min-width: 1200px) {
  .login-block.lg {
    width: 1140px;
    margin-top: 100px;
  }

  .signup-block {
    margin-top: 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
    margin-top: -50px !important;
    margin-right: -80px !important;
  }
}

@media (min-width: 992px) and (max-width: 1150px) {
  .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
    margin-top: 120px !important;
    margin-right: 35px !important;
  }
  .recharts-wrapper .piechart-responsive {
    width: 430px !important;
  }
}
@media (min-width: 1151px) {
  .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
    margin-top: -50px !important;
    margin-right: -100px !important;
  }
}

@media (max-width: 767.98px) {
  .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
    margin-top: -50px !important;
    margin-right: -100px !important;
  }
  .header {
    height: 65px;
  }

  header.body-pd {
    padding-left: 235px;
  }

  .l-navbar {
    width: 225px;
    left: -235px;
  }

  .l-navbar.show {
    left: 0;
  }

  .body-pd .nav_logo-icon {
    background: url("../images/iamsamarth-Logo-Final.svg") no-repeat;
    width: 121px;
    height: 48px;
    background-size: 100%;
    margin-left: 8px;
  }

  .body-pd .nav_name {
    padding-left: 0;
  }

  .main-title {
    margin-top: 75px;
    /* padding-left: 15px; */
  }

  .login-logo {
    width: 150px;
  }

  .login-row {
    margin: 0;
  }

  .sidebar {
    top: 6.75rem;
  }

  .survey-qution .form-check-inline {
    display: block !important;
  }

  .gratejob-text .success-icon {
    display: block;
  }

  .dkpi-block-content img {
    width: 100%;
    height: auto !important;
  }

  .nav_logo,
  .nav_link {
    padding-top: 0.55rem;
  }
}

.progress_bar {
  /* / / background: #959595; */
  /* / background: white; /cx */
  height: 25px;
  border-radius: 5px;
}

.progress_range {
  height: 25px;
  /* width: 69%; */
  background: #09f;
  color: white;
  animation: animate-bar 1s;
}

.progress_range span {
  /* height: 40px; */
  /* width: 30px; */
  float: right;
  /* / margin-top: -15px; /
	   / margin-right: -20px; / */
  color: white;
}

.progress_range_second {
  height: 25px;
  /* width: 69%; */
  background: orange;
  color: white;
  animation: animate-bar 1s;
}

.progress_range_second span {
  /* height: 40px; */
  /* width: 30px; */
  float: right;
  /* / margin-top: -15px; /
	   / margin-right: -20px; / */
  color: white;
}

.nav-tabsnewui.custom-tabsnewui {
  border-bottom: 2px solid #e6e6e6;
}

.nav-tabsnewui.custom-tabsnewui .nav-link.active {
  background: none;
}

.nav-tabsnewui.custom-tabsnewui .nav-link {
  border: none;
  color: #495057;
  text-align: left;
  padding: 5px 0;
  margin-right: 40px;
  position: relative;
  background: white;
}

.nav-tabsnewui.custom-tabsnewui .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #29abe2;
}

.progress_range_cas {
  height: 18px;
  background: #daf6ed;
  color: black;
  animation: animate-bar 1s;
}

.progress_range_cas span {
  /* height: 40px; */
  float: right;
  color: rgb(9, 176, 243);
}

@keyframes animate-bar {
  0% {
    width: 0;
  }
}

.arrow_ups {
  border: 1px solid gray;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-top: 2px !important;
  /* transition: 1s; */
  transform: rotate(-133deg);
  -webkit-transform: rotate(-133deg);
}

.arrow_downs {
  border: 1px solid gray;
  border-width: 0 2px 2px 0;
  display: inline-block;
  /* transition: 1s; */
  padding: 3px;
  margin-top: 2px;

  transform: rotate(48deg);
  -webkit-transform: rotate(48deg);
}

.tab-content.show {
  display: block;
}
.cursor_pointer {
  cursor: pointer;
}

.pic {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
}

.schoolpic {
  overflow: hidden;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.text_arrow {
  font-size: 20px;
  /* float: right; */
  position: absolute;
  top: 26px;
  right: 30px;
}

.profile-pic {
  overflow: hidden;
  width: 100px;
  height: 80px;
  /* border-radius: 50%; */
}
.institute-logo {
  overflow: hidden;
  width: 125px;
  height: 90px;
}

.result-table {
  /* border: 1px solid #cccccc; */
  text-align: center;
}

@media (max-width: 1145px) {
  .event_hall_ticket_scroll {
    width: 100%;
    overflow-x: auto;
    padding: 10px;
  }
  .tm_blck {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
    /* margin-top:-50px !important; */
    margin-right: -70px !important;
  }
}

.recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
  margin-top: -50px !important;
  margin-right: -70px !important;
}

.institute-registered-schools-bg-one {
  background-color: #f5fcff !important;
  color: black !important;
}

.institute-registered-schools-bg-two {
  background-color: #f8fffe !important;
}

.institute-registered-schools-bg-three {
  background-color: #fcf7fc !important;
}
.dkpi-block .institute-registered-schools-bg-one:after {
  background: url("../images/arrow-icon.svg") no-repeat;
}
.dkpi-block .institute-registered-schools-bg-two:after {
  background: url("../images/arrow-icon.svg") no-repeat;
}
.dkpi-block .institute-registered-schools-bg-three:after {
  background: url("../images/arrow-icon.svg") no-repeat;
}
.dkpi-block .institute-registered-schools-bg-four:after {
  background: url("../images/arrow-icon.svg") no-repeat;
}
.institute-registered-schools-bg-four {
  background-color: #fefffa !important;
}
.dkpi-block .dkpi-block-content .dkpi-num {
  color: #4d4d4d !important;
  font-weight: normal !important;
}
.dkpi-num-lg {
  font-size: 52px;
}

.sc-profile-details.small .sc-profile-img {
  width: 100px;
  height: 100px;
  padding: 15px 0 0;
}
.sc-profile-img {
  width: 132px;
  border: 1px solid #e6e6e6;
  background-color: #f5fcff;
  text-align: center;
  border-radius: 4px;
  padding: 22px 0;
}

.sc-profile-text {
  color: #4d4d4d;
}

.sc-profile-contact {
  font-size: 20px;
}

.sc-profile-addrs {
  font-size: 14px;
}

.sc-profile-img label {
  font-size: 5px;
  margin: 0;
  display: block;
}

.sc-profile-viewbtn {
  padding: 3px 10px 3px 10px;
  font-size: 14px;
}

.sc-profile-text h2 {
  font-size: 40px;
  font-weight: 300;
}

.text-mgray {
  color: #999999;
}
.scroll_div_block {
  overflow-y: auto;
  height: 100vh;
}
.section-hd {
  font-size: 18px;
  color: #4d4d4d;
  font-weight: 400;
}

.sc-profile-details.small .sc-profile-text h2 {
  font-size: 28px;
}

.sc-profile-details.small .sc-profile-contact {
  font-size: 15px;
}

.sc-profile-details.small .sc-profile-addrs {
  font-size: 10px;
}
.sc-profile-deg {
  font-size: 14px;
  color: #0f70cd;
  font-weight: 300;
}
.view-arrow {
  background: url(../images/view-arrow.svg) no-repeat;
  width: 6px;
  height: 8px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: initial;
}

.sc-profile-viewbtn .view-arrow {
  transform: rotate(90deg);
}
.sc-profile-viewbtn.collapsed .view-arrow {
  transform: rotate(0deg);
}
.filter-section button {
  background: none;
  border: none;
  padding: 0;
}
.filter-section small {
  font-size: 11px;
  color: #808080;
}
.school-card .sc-card-content {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  text-decoration: none;
  display: block;
  padding: 6px 13px;
  color: #4d4d4d;
  position: relative;
}

.school-card .sc-card-content:hover {
  border-color: #ffffff;
  box-shadow: 1px 1px 3px #888888;
  background-color: #f5fcff;
}

.school-card .sc-card-content:after {
  content: "";
  position: absolute;
  background: url("../images/play-arrow.svg") no-repeat;
  width: 29px;
  height: 21px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.5s;
}

.school-card .sc-card-content:hover:after {
  right: -6px;
}

.sc-card-img {
  flex: 90px 0 0;
  border-right: 1px solid #e6e6e6;
}

.sc-card-img p {
  font-size: 11px;
  line-height: 11px;
}

.sc-card-img label {
  font-size: 5px;
  margin: 0;
  display: block;
}

.sc-card-text {
  padding-left: 15px;
}

.sc-card-text h6 {
  font-size: 12px;
  font-weight: 400;
}

.sc-card-text h3 {
  font-size: 22px;
  font-weight: 400;
}

.sc-card-text p {
  font-size: 10px;
}
.filter-icon {
  background: url(../images/filter-icon.svg) no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
.filter-section-fileds {
  background-color: #f5fcff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.filter-section-fileds label {
  color: #333333;
  font-size: 14px;
  margin-bottom: 2px;
}

.filter-section-fileds .form-control,
.filter-section-fileds .form-select,
.filter-search .form-control {
  font-size: 12px;
  color: #b3b3b3;
  border-color: #cccccc;
}

.filter-section-fileds .btn-primary,
.filter-search .btn-primary {
  background: #0f70cd;
  padding: 4px 20px;
  font-size: 15px;
  border-radius: 4px !important;
}

.filter-section-fileds .btn-link {
  color: #000000;
  text-decoration: none;
}

.filter-section-fileds .accordion-button {
  box-shadow: none;
  background-color: #f5fcff;
  padding: 0.5rem;
}

.filter-section-fileds .accordion-button::after {
  background: none;
}

.filter-section-fileds .accordion-button:not(.collapsed) {
  color: #000000;
}

.filter-section-fileds .accordion-body {
  padding: 0.5rem;
}

.filter-section-fileds .filter-icon {
  margin-right: 8px;
}
.filter-search {
  position: relative;
}

.filter-search .form-control {
  padding-right: 90px;
}

.filter-search .btn {
  position: absolute;
  right: 0;
  top: 0;
}

.ft_weight {
  font-weight: bold;
}

.piecharthieght {
  min-height: 250px !important;
}
.recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
  margin-top: -50px !important;
  margin-right: -80px !important;
}
.pr-logo {
  overflow: hidden;
  width: 129px;
  height: 123px;
  /* border-radius: 50%; */
}
.dkpi-green-bg {
  background-color: #f8fffe;
}
.dkpi-block-content.no-hover:hover {
  box-shadow: none;
  border-color: #e6e6e6;
}
.dkpi-blue-bg {
  background-color: #f5fcff;
}
.dkpi-yellow-bg {
  background-color: #fefffa;
}
.dkpi-pink-bg {
  background-color: #fcf7fc;
}
.section-hd small {
  display: block;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 10px;
}
.nav-icon.modules-icon {
  background: url("../images/modules-icon.svg") no-repeat;
}

.breadcrumb .breadcrumb-item.text-subc {
  color: #009245 !important;
}
.breadcrumb .breadcrumb-item.text-notsubc {
  color: #f78181 !important;
}
.module-card {
  padding: 30px 20px;
  border: 1px solid #0f70cd;
  border-radius: 4px;
  background-color: #e8f3fc;
  min-height: 205px;
  position: relative;
}
.module-card.full {
  min-height: inherit;
}
.module-card .module-title {
  font-size: 42px;
  font-weight: 300;
}
.module-card.full .module-title {
  font-size: 52px;
}
.module-card h5 {
  font-size: 16px;
  font-weight: 400;
}
.module-card.full h5 {
  font-size: 20px;
}
.module-card p {
  font-size: 13px;
  font-weight: 500;
}
.module-card.card-two {
  border-color: #a67c52;
  background-color: #efe3e1;
}
.module-card.card-three {
  border-color: #dd8872;
  background-color: #e2d6d2;
}
.module-card.card-four {
  border-color: #d6564c;
  background-color: #e0c5c0;
}
.text-completed {
  color: #39b54a;
}

.btn-continue {
  background-color: #0071bc;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
}
.btn-continue:hover,
.btn-continue:focus {
  background-color: #0090f0;
  color: #ffffff;
}
.text-inprogess {
  color: #f15a24 !important;
}
.btn-pay:hover,
.btn-pay:focus {
  background-color: #ff6161;
  color: #ffffff;
}
.btn-pay {
  background-color: #f93d3d;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
}

.module-completed-logo {
  position: absolute;
  bottom: 40px;
  right: 20px;
}
.module-section {
  color: #4d4d4d;
}
.module-section h2 {
  font-size: 22px;
  font-weight: 300;
}
.module-section h3 {
  font-size: 18px;
  font-weight: 300;
}
.module-price {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}
.module-price small {
  display: block;
  font-size: 12px;
  color: #4d4d4d;
  font-weight: 300;
  line-height: 12px;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-light {
  font-weight: 300 !important;
}
.f22 {
  font-size: 22px;
}
.time-block {
  background-color: #f5fcff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  position: sticky;
  top: 0px;
  /* z-index: 999; */
  z-index: 100;
  min-height: 85px;
}

#navbar--fixed-top {
  position: -webkit-sticky;
  position: sticky !important;
  /* top: 90px !important; */
  top: 64px !important;
  z-index: 100;
  /* width: 83%; */
}

.time-block h2 {
  font-size: 24px;
  font-weight: 400;
}
.time-block h3 {
  font-size: 18px;
  font-weight: 400;
}
.time-block h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 26px;
}
.time-block h1 small {
  font-size: 18px;
}
.question-block ul li input {
  width: 100%;
}
.question-block h3 {
  font-size: 18px;
  font-weight: 500;
}
.question-block ul li label {
  font-weight: 300;
  font-size: 18px;
}
.question-block ul li input {
  border-radius: 6px;
  border: 1px solid #cccccc;
  padding: 5px;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.btn-link {
  color: #000000;
  text-decoration: none;
}
.filter-section-fileds .btn-link {
  color: #000000;
  text-decoration: none;
}

.status-cards {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 16px;
  min-height: 80px;
}
.status-cards label {
  cursor: pointer;
}
.status-cards span {
  font-size: 12px;
}
.status-cards .text-score {
  font-size: 44px;
  line-height: 44px;
}
.status-cards .status-cards-arrow {
  background: url("../images/status-cards-arrow.svg") no-repeat;
  width: 35px;
  height: 25px;
  display: inline-block;
}

.status-cards.collapsed .status-cards-arrow {
  transform: rotate(-90deg);
  position: absolute;
  right: 16px;
  top: 27px;
}
.status-cards .status-cards-arrow.small {
  background: url("../images/status-cards-arrow-small.svg") no-repeat;
  width: 12px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.attempts .list-inline-item:not(:last-child) {
  margin-right: 0.2rem;
}
.attempts li {
  width: 26px;
  height: 26px;
  color: #000000;
  border-radius: 50%;
  border: 1px solid #a67c52;
  text-align: center;
}
.attempts li a {
  display: block;
  color: #000000;
}
.attempts li:hover {
  background-color: #cccccc;
}
.attempts li.completed {
  background-color: #8cc63f;
}
.attempts li.current {
  background-color: #fcee21;
}
.status-cards .attempts {
  line-height: 14px;
}
.status-cards .attempts li {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.attempts .list-inline-item:not(:last-child) {
  margin-right: 0.2rem;
}
.text-mdgray {
  font-weight: 500;
  color: #4d4d4d;
}
.success-block .lelvel-text {
  font-size: 28px;
}
.q-input {
  width: 100%;
}

.q-name {
  font-weight: 300;
  font-size: 18px;
}
.q-input {
  border-radius: 6px;
  border: 1px solid #cccccc;
  padding: 5px;
}
.q-number {
  font-size: 18px;
  font-weight: 500;
}
.status-cards label {
  cursor: pointer;
}
.fw-medium {
  font-weight: 500;
}
.f20 {
  font-size: 20px;
  line-height: 20px;
}
.side-sticky {
  position: sticky !important;
}
.side-sticky .fixed {
  position: fixed !important;
  top: 160px;
}
.pr-image {
  overflow: hidden;
  width: 97px;
  height: 97px;
  /* border-radius: 50%; */
}
.nav-tabsnewui.custom-tabsnewui .pr.active:after {
  background-color: #6359ef;
}

.text-dgray {
  color: #333333;
}
.module-card.psurvey .survey-link:after {
  content: "";
  position: absolute;
  background: url(../images/arrow-icon.svg) no-repeat;
  width: 29px;
  height: 21px;
  right: 12px;
  bottom: 8px;
  transition: right 0.5s;
}

.module-card.psurvey .survey-link:hover:after {
  right: 2px;
}
.animated-text {
  animation: textAnimation 1s ease-in-out;
}
.block_text {
  height: 200px;
  overflow: hidden;
}
@keyframes textAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
.marked_text {
  color: #aaa;
}
.image-to-grey {
  filter: grayscale(100%);
}
.z_index_ni {
  /* z-index: 9999; */
}
.bx-menus {
  background: url(../images/header-toggle-icon.svg) no-repeat;
  width: 15px;
  height: 15px;
}
#newtask {
  position: relative;
}
#newtask input {
  width: 75%;
  height: 45px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  border: 2px solid #d1d3d4;
  padding: 12px;
  color: #111111;
  font-weight: 500;
  position: relative;
  border-radius: 5px;
}
#newtask input:focus {
  outline: none;
  border-color: #0d75ec;
}

#newtask button {
  position: relative;
  float: right;
  width: 15%;
  height: 40px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: #0d75ec;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
}
#tasks {
  background-color: #ffffff;
  padding: 30px 20px;
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
  position: relative;
}
.task {
  background-color: #c5e1e6;
  height: 50px;
  margin-bottom: 8px;
  padding: 5px 10px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #939697;
  cursor: pointer;
}
.task span {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.task button {
  background-color: #0a2ea4;
  color: #ffffff;
  height: 100%;
  width: 40px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  outline: none;
}

.completed {
  text-decoration: line-through;
}
.ab_blue {
  background-color: #d3ecf0 !important;
}
.sc_blue {
  background-color: #d3dace;
}
.md_blue {
  background-color: #a9c3b6;
}
.test_fixed-table-header {
  border: none;
  width: 99.9%;
}

.test_fixed-table-header th {
  position: sticky;
  top: 0;
  /* background: #e6e6e6 !important; */

  z-index: 2;
  /* border-bottom: 1px solid #ccc; */
  border-bottom: 2px solid #17a2b8 !important;
  background: white !important;
  min-width: 100px;
}

.test_fixed-table-header th:after {
  content: "";
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  background: white;
  width: 1px;
}

.test_fixed-table-header > thead > tr > th:first-child,
.test_fixed-table-header > tbody > tr > td:first-child {
  border-left: none;
}

.test_fixed-table-header > tbody > tr:last-child > td {
  border-bottom: none;
}

.test_fixed-table-header.table > :not(caption) > * > * {
  /* background-color: #f2f2f2; */
  background: white;
  /* border-color: #ccc !important; */
}

.test_fixed-table-header.table > thead > :not(caption) > * > * {
  border-top: none;
}

.pariksha-info {
  border: solid 1px #e3e3e3;
  border-radius: 25px;
  background-color: #e8eeef;
  padding: 37px 50px;
}

.pariksha-model-content {
  border-radius: 15px;
}

.pariksha-model-info {
  text-align: center;
}

.test-btn {
  padding: 2px 30px;
  border: none;
  background-color: #e6eff1;
  border: solid 1px #e6eff1;
  margin-right: 20px;
}

.form-select-border {
  border: solid 1px #142b4f;
  border-radius: 5px;
}

.abhyas-fixed-top {
  position: sticky;
  top: 0px !important;
}
.abhyas-logo {
  z-index: 999 !important;
  background-color: #f7f7f7;
  position: sticky;
}
.img_mathjx {
  /* width: 190px;
  height: 170px; */
  /* object-fit: contain; */
  width: 300px;
  height: 240px;
  object-fit: cover;
}
.img_mathjx_q {
  /* width: 250px;
  object-fit: contain; */
  width: 250px;
  height: 170px;
  object-fit: cover;
}
.img_mathjx_optn {
  width: 180px;
  height: 160px;
  object-fit: cover;
  /* object-fit: contain; */
}
.q_zindex {
  z-index: 1;
}
.time-block-app {
  background-color: #f5fcff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  position: sticky;
  top: 64px;
  /* z-index: 999; */
  /* z-index: 100; */
  z-index: 1000;
  /* min-height: 50px; */
}
.result-list-tps .tps {
  border: 1px solid #29abe2;
  font-size: 15px;
  padding: 2px 8px;
  border-radius: 5px;
  color: #000000;
  display: block;
}
.result-list-tps .tps:hover {
  background-color: #29abe2;
  color: #ffffff;
}
.text-mblue {
  color: #29abe2 !important;
}
.status-cards-arrow.medium.tr {
  transform: rotate(-90deg);
  position: absolute;
  right: 16px;
  top: 27px;
}
.status-cards .status-cards-arrow.medium {
  background: url(../images/status-cards-arrow-medium.svg) no-repeat;
  width: 20px;
  height: 15px;
  display: inline-block;
}
.status-cards .card-tr {
  transform: rotate(-90deg) !important;
  position: absolute;
  right: 16px;
  top: 27px;
}
.table_new_ui .bg-tpc {
  background-color: var(--lblue-color) !important;
}

.q_board {
  /* / display: flex; / */
  max-width: 218px;
  /* / height: 210px; / */
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid;
  flex-wrap: wrap;
  border-radius: 15px;
  padding: 10px;
  background-color: #f4fcff;
}
.qno_card {
  /* background: #a20; */
  min-width: 30px;
  min-height: 27px;
  flex-shrink: 0;
  /* margin: 10px; */
  align-items: center;
  border-radius: 5px;
}
.q_card {
  width: 280px;
  display: flex;
  word-wrap: break-word;
}
.btn-test {
  background-color: #d9e1e7;
  color: black;
  font-size: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 3px 16px;
}
.p_pink {
  background-color: #f9e3d4;
  border: none;
  padding: 25px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 7px;
}
.p_select {
  background-color: #f9e3d4;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 3px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 7px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 24px;
  padding-right: 10px;
}
.task_list_img {
  width: 80%;
  height: 90%;
}
.ps_report_gf {
  width: 55%;
}
.ab_btn {
  /* background-color: rgb(168, 185, 202); */
  background-color: #76a4cc;
  border: none;
  padding: 9px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: 500;
  color: white;
  border-radius: 7px;
}
.bg_org {
  background-color: rgb(255, 69, 0);
}

.ex_pink {
  background-color: #ffe8e8;
}
.ex_blu {
  background-color: #d7e2d0;
}

.q_text_color {
  color: #4c89c1;
}
.analysis_area {
  width: 90%;
  height: 90%;
}

.avg_bg {
  background-color: #f29f05;
}
.avg_text {
  color: #f29f05;
}
.area_bx {
  width: 100px;
  /* height: 100px; */
}
.sg_bd {
  border: 2px solid #616366;
  border-style: dashed;
  border-radius: 8px;
  border-bottom: none;
}
.wek_bg {
  background-color: #c00000;
}
.wek_text {
  color: #c00000;
}
.gd_bg {
  background-color: #80b26b;
}
.gd_text {
  color: #80b26b;
}
.bst_bg {
  background-color: #b6d93b;
}
.bst_text {
  color: #b6d93b;
}
.sw_bg {
  background-color: #2f5597;
}
.sw_text {
  color: #2f5597;
}
.ft_bg {
  background-color: #ff0000;
}
.ft_text {
  color: #ff0000;
}
.analysis_vl {
  border-left: 3px solid #afabab;
  height: 90px;
}
.analysis_ft_area {
  width: 100%;
  height: 100%;
}
.test_area {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6%;
}

.all_bx {
  background-color: #dbe3e9;
  padding-left: 51px;
  padding-right: 18px;
  padding-bottom: 7px;
  padding-top: 6px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 7px;
  margin-top: 17px;
  width: 180px;
  /* left: 38px; */
}
.test_summary_mg {
  width: 98%;
  height: 98%;
}
.timer_gf {
  width: 15%;
  height: 15%;
}
.subject-list-view {
  background-color: #d8e0e7;
  width: 150px;
  margin-bottom: 2px;
  /* padding: 5px, 4px; */
  padding-left: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 4px;
}
.pariksha-multi-info {
  border-radius: 50px;
  background-color: #fbe3d6;
  padding: 37px 55px;
  margin: 0px 25px;
}
.pariksha-model-border {
  border: 1px dashed #697b91;
  padding: 20px;
  border-spacing: 5px;
  border-radius: 10px;
}
.questions-info {
  padding: 10px 40px;
  text-align: initial;
  font-weight: 500;
  font-size: 15px;
}
.test-header {
  text-decoration: underline;
  color: #164d77;
}
.payment_user_popup_info {
  background-color: #ffffff;
}
.payment_user_popup_block {
  padding: 20px;
}
.payment_modal-dialog_popup {
  max-width: 400px;
  margin: 1.75rem auto;
}
.test_create_modal-dialog_popup {
  max-width: 400px;
  margin: 5.75rem auto;
}
.subscribe_btn_user {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 0px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.subscribe_btn_user:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
.payment_user_btn {
  /* width: 80%; */
  background-color: #f24a09;
  color: white;
}
.question_source_btn {
  /* width: 80%; */
  background-color: #156082;
  color: white;
}
.source_filed_bg {
  background-color: #f2f2f2;
}
.payment_mem_text {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 25px;
}
.pariksha-model-info {
  text-align: center;
  background-color: #f2fbff;
  box-shadow: 5px 5px 10px #757575;
  /* Adjust values as needed */
}
.test-btn {
  padding: 4px 0px;
  width: 80px;
  border: none;
  background-color: #1f73b1;
  border: none;
  margin-right: 20px;
  color: white;
}
.start-test h6 {
  /* text-align: initial; */
  font-size: 18px;
  color: #436e91;
  font-weight: 500;
}

.p-form-select-border {
  border: solid 1px white;
  border-radius: 5px;
}

/* Updated CSS with responsive width */
.question-pattern {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 100%;
  box-sizing: border-box;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, 35px);
  gap: 5px;
  max-width: 100%; */
}
/* .question-pattern .q_item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #3d70c0;
  box-sizing: border-box;
} */

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  /* Adjust the width based on the container's size */
  height: 25px;
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #3d70c0;
  box-sizing: border-box;
  /* Includes padding and border in the element's total width and height */
  text-align: center;
  margin: 2px;
  /* Adjust the margin between numbers */
  color: white;
  border-radius: 5px;
  font-size: 12px;
}

/* Clear the left after every 5th element */
.number:nth-child(5n + 1) {
  clear: left;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .pf_txt {
    left: 28%;
    top: 45%;
  }
  .number {
    width: 30%;
    /* Adjust width for smaller screens */
  }
  .img_ck img {
    max-width: 97%;
  }
  .img_ck_otp img {
    max-width: 60%;
  }
  .test_area {
    gap: 20% !important;
  }
  .module_container {
    flex-direction: column;
    align-items: center;
  }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* CSS */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .pariksha-multi-info {
    border-radius: 50px;
    background-color: #fbe3d6;
    padding: 6px 15px;
    margin: 0px 25px;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .pariksha-multi-info {
    border-radius: 50px;
    background-color: #fbe3d6;
    padding: 6px 15px;
    margin: 0px 25px;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .pariksha-multi-info {
    border-radius: 50px;
    background-color: #fbe3d6;
    padding: 6px 15px;
    margin: 0px 25px;
  }

  #q_timer_block {
    position: relative;
    top: 0px;
    z-index: 100;
  }
  .payment_year-select {
    font-size: 14px;
    padding: 6px;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .pariksha-multi-info {
    border-radius: 50px;
    background-color: #fbe3d6;
    padding: 6px 15px;
    margin: 0px 25px;
  }
  .payment_year-select {
    font-size: 12px;
    padding: 5px;
  }
}

.t-questions {
  text-decoration: underline;
}

.submit-btn-test {
  padding: 4px 40px;
  background-color: #1f73b1;
  color: white;
  font-size: 14px;
  border: none;
}

.square-answer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  /* Adjust the width based on the container's size */
  height: 18px;
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #3d70c0;
  box-sizing: border-box;
  /* Includes padding and border in the element's total width and height */
  text-align: center;
  margin: 2px;
  /* Adjust the margin between numbers */
  color: white;
  border-radius: 5px;
  font-size: 12px;
}

.square-not-answer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  /* Adjust the width based on the container's size */
  height: 18px;
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #c70208;
  box-sizing: border-box;
  /* Includes padding and border in the element's total width and height */
  text-align: center;
  margin: 2px;
  /* Adjust the margin between numbers */
  color: white;
  border-radius: 5px;
  font-size: 12px;
}

.answer {
  font-size: 10px;
  color: #3d70c0;
  padding-top: 5px;
  padding-left: 5px;
}

.not-answer {
  font-size: 10px;
  padding-top: 5px;
  color: #c70208;
  padding-left: 5px;
}

.q-primary {
  background-color: #3d70c0;
}

.q-danger {
  background-color: #c70208;
}
.ab_select {
  background-color: #f9e3d4;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 3px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 7px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 24px;
  padding-right: 10px;
  width: 210px;
}
.parent_ab_select {
  background-color: #f9e3d4;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 3px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 7px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 24px;
  padding-right: 10px;
}
.ab_select_drop_list {
  position: absolute;
  display: block;
  right: 0;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ab_test_select {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.ab_icon {
  position: relative;
  left: 35px;
}
.parent_ab_icon {
  position: relative;
}
.ab_ass {
  position: relative;
  left: 17px;
}
.ed_img {
  width: 95%;
  height: 100%;
}

.ab_custom_list {
  margin: 0;
  text-align: left;
  display: inline-block;
}
.ab_custom_list li {
  list-style-position: outside;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 4px 4px 13px;
}

/* Triangle CSS */
.triangle_analysis {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  display: block;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  /* border-bottom: 15px solid #000;  */
  position: relative;
  display: block;
  /* bottom: -15px; */
  margin-top: 15px;
  transform: translateX(-50%);
  top: 8px;
}

/* Define classes for different test types */
.triangle-allocated {
  border-bottom: 30px solid #5233ff; /* Orange color */
}

.triangle-attempted {
  border-bottom: 30px solid #66ccff; /* Green color */
}

.triangle-passed {
  border-bottom: 30px solid #52cc99; /* Orange color */
}

.triangle-failed {
  border-bottom: 30px solid #ff5033; /* Green color */
}

/* Table-like structure CSS */
.test-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 15px; /* Adjust as needed for spacing */
}

.test-table th,
.test-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}
.nw_nav {
  background-color: #dbe3e9;
  margin: 0px 18px 0px 10px;
  border-radius: 15px;
  padding-top: 15px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.srl_bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.srl_bar::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.srl_bar::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

.side_bar_icons {
  height: 50px;
  width: 50px;
  filter: invert(55%);
}
.moleculer {
  position: relative;
  left: 10px;
}
.mathematics {
  position: relative;
  left: 120px;
}
.test_sm_btn {
  padding: 4px 33px;
  text-align: center;
  color: white;
  border: none;
  font-size: 18px;
  cursor: context-menu !important;
}
.triangle-weak {
  border-bottom: 30px solid #c00000;
}
.triangle-avg {
  border-bottom: 30px solid #f29f05;
}
.triangle-gd {
  border-bottom: 30px solid #80b26b;
}
.triangle-bt {
  border-bottom: 30px solid #b6d93b;
}
.triangle-sw {
  border-bottom: 30px solid #2f5597;
}
.triangle-ft {
  border-bottom: 30px solid #ff0000;
}
.sd_icons {
  color: #666262 !important;
  font-size: 17px;
}
.blue_text {
  color: #436e91;
  font-weight: 500;
}
.und_line {
  -webkit-text-decoration: #d0cece 1px solid underline;
  text-decoration: #d0cece 1px solid underline;
  -webkit-text-underline-position: under;
  text-underline-position: under;
  text-underline-offset: 5px;
}
.question_no_block {
  height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide scrollbar UI for WebKit (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.z_100 {
  z-index: 100;
}
.z_9999 {
  z-index: 9999;
}
.q_content-block {
  /* overflow: hidden; */
  white-space: nowrap;

  text-overflow: ellipsis;

  display: inline-block;

  /* overflow: visible; */
  /* white-space: normal; */
}
.mb_0 p {
  margin-bottom: 0px;
}
.q_display_block {
  min-height: 440px;
}
.report_border {
  border: 1px solid #960000;
}
.maths_initiative_btn {
  color: white;
  font-size: 18px;
  padding: 2px 16px;
  border-radius: 11px;
  background-color: #0f72cb;
  box-shadow: 3px 2px 4px #00265f;
}
.maths_week_btn {
  color: white;
  font-size: 12px;
  padding: 3px 16px;
  border-radius: 4px;
  background-color: #ff4200;
}
.maths_week_btn_time {
  color: white;
  font-size: 12px;
  padding: 3px 16px;
  border-radius: 4px;
  background: linear-gradient(to right, #8dc262 50%, #ed8d57 50%);
  /* background: linear-gradient(90deg, #8dc262 30%, #ed8d57 70%); */
}
.maths_time_card {
  color: #1b74a2;
  border: 2px solid #a4b5bb;
  background-color: #e8f8ff;
  border-radius: 7px;
}
.maths_recom_btn {
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #468ed6;
}
.math_list {
  list-style-type: square;
  font-size: 14px;
}
.mns_table_container {
  overflow-x: auto;
  border-radius: 33px;
}
table.mns_table_case-1 {
  width: 100%;
  /* border-collapse: collapse; */
}
.mns_table_case-1 th {
  background: #9e0808;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
}
.mns_table_case-1 td {
  background: #fae2d5;
  font-size: 14px;
  padding: 10px 0px;
}
.mns_table_case-1 th,
.mns_table_case-1 td {
  border: 1.5px solid white;
}
.cl_ble {
  color: #0070c0;
}
.un_line {
  text-decoration: underline #0070c0;
  text-decoration-skip-ink: none;
}
.txt_mns_org {
  color: #ff0000;
}
.entry_ul {
  text-decoration: underline 1px #000;
  font-size: 17px;
  font-weight: 500;
  color: #000000;
}
.under_line_skip_ink {
  text-decoration-skip-ink: none;
}
.mns_cases {
  text-decoration: underline 1px #000;
}
.mns_case_cl {
  color: #000000;
  font-weight: 500;
}
table.mns_table_case-2 {
  width: 100%;
  border-collapse: collapse;
}
.mns_table_case-2 th {
  background: #fec308;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
}
.mns_table_case-2 td {
  background: #ffffcb;
  font-size: 14px;
  padding: 10px 0px;
}
.mns_table_case-2 th,
.mns_table_case-2 td {
  border: 1.5px solid white;
}
table.mns_table_case-3 {
  width: 100%;
  border-collapse: collapse;
}
.mns_table_case-3 th {
  background: #153d63;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
}
.mns_table_case-3 td {
  background: #c1e4f5;
  font-size: 14px;
  padding: 10px 0px;
}
.mns_table_case-3 th,
.mns_table_case-3 td {
  border: 1.5px solid white;
}
table.mns_table_case-4 {
  width: 100%;
  border-collapse: collapse;
}
.mns_table_case-4 th {
  background: #124f1a;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
}
.mns_table_case-4 td {
  background: #d9f2d0;
  font-size: 14px;
  padding: 10px 0px;
}
.mns_table_case-4 th,
.mns_table_case-4 td {
  border: 1.5px solid white;
}
table.mns_practice_level {
  width: 100%;
  border-collapse: collapse;
}

.mns_practice_level td {
  font-size: 14px;
  color: #000000;
}
.mns_practice_level th {
  font-size: 14px;
  color: white;
  padding: 5px 7px;
  font-weight: 500;
}
.mns_practice_level th,
.mns_practice_level td {
  border: 1.5px solid white;
}
.mns_practice_case-1 th {
  background: #960000;
}
.mns_practice_case-1 td {
  background: #fae2d5;
}

.mns_practice_case-1 .bg-level {
  background-color: #f1a983;
}
.mns_practice_case-2 th {
  background: #f6bb00;
}
.mns_practice_case-2 td {
  background: #ffffc9;
}

.mns_practice_case-2 .bg-level {
  background-color: #ffe285;
}
.mns_practice_case-3 th {
  background: #156082;
}
.mns_practice_case-3 td {
  background: #f7f7f7;
}

.mns_practice_case-3 .bg-level {
  background-color: #93d1ed;
}
.mns_practice_case-4 th {
  background: #124f1a;
}
.mns_practice_case-4 td {
  background: #eafaec;
}

.mns_practice_case-4 .bg-level {
  background-color: #aeecb7;
}
table.mla_one_table_report {
  width: 100%;
  border-collapse: collapse;
}
.mla_one_table_report th {
  background: #1e6c56;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
}
.mla_one_table_report td {
  background: #f5fdfb;
  padding: 6px 10px;
  font-size: 14px;
}
.mla_one_table_report th,
.mla_one_table_report td {
  border: 1.5px solid white;
}
.mla_one_table_report .col_one {
  background: #def6ef;
}
.report_col_wd {
  width: 300px;
}
.priority_ul {
  text-decoration: underline 1px #c00000;
  text-decoration-skip-ink: none;
  color: #c00000;
}
table.mla_two_table_report {
  width: 100%;
  border-collapse: collapse;
}
.mla_two_table_report th {
  background: #4f2270;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
}
.mla_two_table_report td {
  background: #faeaf8;
  padding: 6px 10px;
  font-size: 14px;
}
.mla_two_table_report th,
.mla_two_table_report td {
  border: 1.5px solid white;
}
.mla_two_table_report .col_one {
  background: #f5d7f1;
}
table.report_priority_table {
  width: 100%;
  border-collapse: collapse;
}

.report_priority_table td {
  font-size: 14px;
  color: #000000;
  padding: 5px 0px 5px 0px;
}
.report_priority_table th {
  font-size: 14px;
  color: white;
  padding: 5px 1px 5px 0px;
  font-weight: 500;
}
.report_priority_table th,
.report_priority_table td {
  border: 1.5px solid white;
}
.report_priority_table .col_one {
  width: 170px;
  padding-left: 7px;
}
.priority_1 th {
  background: #006699;
}
.priority_1 td {
  background: #e0edf8;
}

.priority_1 .col_one {
  background-color: #b4d2ee;
}
.priority_2 th {
  background: #808000;
}
.priority_2 td {
  background: #ecfbc9;
}

.priority_2 .col_one {
  background-color: #e1e587;
}
.priority_3 th {
  background: #660033;
}
.priority_3 td {
  background: #fde3ed;
}

.priority_3 .col_one {
  background-color: #fac6da;
}
.report_ias_icon {
  background: url("../images/iamsamarth-Logo-Final.svg") no-repeat;
  display: block;
  width: 130px;
  height: 50px;
  background-size: 100%;
  transition: 0.5s;
}
.report_bg_img {
  width: 100%;
  border: 1px solid #aea5a5;
  border-radius: 25px;
  height: 227px;
}
.profie_img_bx {
  width: 66px;
  height: 24px;
  bottom: 127px;
  left: 80px;
  border-radius: 4px;
}
.report_logo_icon {
  height: 47px;
  width: 47px;
  border-radius: 50%;
}
.ap_btns {
  font-size: 12px;
  padding: 4px 10px;
  color: white;
  border-end-end-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #13455f;
}
.app_qestion_board {
  border: #9fa6a7 2px solid;
  margin: 0px 18px;
  border-radius: 5px;
  position: relative;
  background-color: #f3fbfe;
}
.app_q_no {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 25px;
  border: 1px solid #000;
  border-radius: 3px;
  box-sizing: border-box;
  text-align: center;
  margin: 3px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
}
.app_queston_dwn {
  position: absolute;
  width: 26px;
  height: 25px;
  background: #e65109;
  color: white;
  border-radius: 50px;
  left: 50%;
}

.hide_q_block {
  display: none;
  transition: height 1s ease;
}
.q_view {
  /* transition: height 0.5s ease; */
  transition: height 1s ease;
}
.app_opt input[type="radio"] {
  appearance: none;
  background-color: #fff;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
  box-shadow: 2px 3px 0px black;
}

.app_opt input[type="radio"]::before {
  content: "";
  width: 11px;
  height: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #fff;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.app_opt input[type="radio"]:checked::before {
  transform: scale(1);
}
.app_opt input[type="radio"]:checked {
  background-color: #0075ff;
  border: 2px solid #0075ff;
}
.app_square-answer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  /* Adjust the width based on the container's size */
  height: 13px;
  border: 1px solid #000;
  background-color: #3d70c0;
  box-sizing: border-box;
  /* Includes padding and border in the element's total width and height */
  text-align: center;
  margin: 2px;
  /* Adjust the margin between numbers */
  color: white;
  border-radius: 3px;
  font-size: 12px;
}

.app_square-not-answer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  /* Adjust the width based on the container's size */
  height: 13px;
  border: 1px solid #000;
  background-color: #c70208;
  box-sizing: border-box;
  /* Includes padding and border in the element's total width and height */
  text-align: center;
  margin: 2px;
  /* Adjust the margin between numbers */
  color: white;
  border-radius: 3px;
  font-size: 12px;
}
.app_answer {
  font-size: 10px;
  color: #3d70c0;
  padding-top: 2px;
  padding-left: 5px;
}

.app_not-answer {
  font-size: 10px;
  padding-top: 2px;
  color: #c70208;
  padding-left: 5px;
}
.app_question_no_block {
  height: 175px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide scrollbar UI for WebKit (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.app_bg_img {
  position: relative;
}
.app_bg_img:after {
  content: "";
  background: url("../../institute-dashboard-assets/images/app-bg-img.png")
    no-repeat center center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3; /* Here is your opacity */
}
.app_expl_src {
  /* position: relative; */
  background: transparent !important;
}
/* .app_expl_src:after {
  content: "";
  background: url("../../institute-dashboard-assets/images/app-bg-img.png")
    no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;
  opacity: 0.3; 
  z-index: -1;
} */
.app_question_src {
  display: flex;
  flex-flow: column;
  /* min-height: calc(100vh - 35px); */
}
.profie_img_up_icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #2c93ec;
  padding-top: 4px;
  padding-left: 6px;
}
.profile_img_crd {
  height: 130px;
  width: 135px;
  position: relative;
}
.pf_img_icon {
  position: absolute;
  bottom: 0;
  left: 105px;
  /* background: #00b4ff; */
  background: #2c93ec;
  width: 32px;
  height: 32px;
  line-height: 33px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.pf_img_icon input[type="file"] {
  position: absolute;
  transform: scale(2);
  opacity: 0;
}
.pr-img_logo {
  width: 130px;
  height: 125px;
  overflow: hidden;
  object-fit: cover;
}
.profile_blck {
  width: 133px;
  border: 1px solid #e6e6e6;
  background-color: #f5fcff;
  text-align: center;
  border-radius: 4px;
  padding: 22px 0;
}
.module_logo_img {
  height: 25%;
  width: 25%;
  right: 20px;
  top: 22px;
  position: absolute;
  object-fit: contain;
}
.module_icons {
  height: 52px;
  width: 82px;
  object-fit: contain;
}

.report_time_dsply {
  /* margin-inline-start: 42px; */
  display: flex;
  flex-wrap: wrap;
  gap: 6%;
}
.tm_blck {
  display: flex;
  justify-content: space-around;
}
.pf_txt {
  position: absolute;
  left: 33%;
  top: 45%;
}
.prx_sm {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6%;
}
.free_trial_btn {
  color: #fff;
  background-color: #c82333;
  padding: 2px 4px;
  border-radius: 2px;
  font-weight: 700;
  letter-spacing: 1px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
}
.worksheet_tooltip {
  position: absolute;
  padding: 2px 4px;
  background-color: #e0e6ec;
  color: #212529;
  font-size: 13px;
  z-index: 100;
  top: 27px;
  border-radius: 2px;
}
.img_upload_blck {
  background-color: #f4f4f4;
  max-width: 152px;
  max-height: 132px;
  border: 1px solid lightgrey;
  border-radius: 5px;
}
.img_up_btn {
  background-color: #e9eaeb;
  border: 2px solid #7e9bab;
  color: #4d4a49;
  font-size: 15px;
  font-weight: 500;
  padding: 4px 28px;
}
.img_up_logo {
  height: 130px;
  width: 150px;
  overflow: hidden;
  object-fit: cover;
}
.md_dashboard_txt {
  display: inline;
  padding: 5px 29px;
  background-color: #dae2ea;
  border-radius: 5px;
}

.q_nxt_btn {
  background-color: #00a2e8;
  color: white;
  font-family: "EB Garamond";
  font-weight: 600;
  border: #00a2e8;
  padding: 5px 25px;
}
.q_nxt_btn.btn_disabled {
  background-color: lightgrey !important;
}

.pay_fees {
  background-color: rgb(233, 236, 239);
  min-height: 150px;
  width: 300px;
  border-radius: 2%;
}
.event_id_block {
  background: url("../../institute-dashboard-assets/images/event-id-background.png")
    no-repeat center center;
  /* min-height: 550px; */
  /* height: 743px; */
  height: 866px;
  /* width: 530px; */
  /* width: 591px; */
  width: 625px;
  border: 1.5px solid #c9cbd0;
}
.event_id_one {
  /* background-image: url("../../institute-dashboard-assets/images/event-id-background.png");
  background-repeat: no-repeat; */
  background: url("../../institute-dashboard-assets/images/event-id-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 400px;
}
.terms_text {
  font-family: "EB Garamond" !important;
}
.bg-event {
  /* min-height: 600px;
  width: 570px; */
  /* width: 570px; */
  width: 655px;

  /* background-color: #fae1b9; */
}
.term_block {
  background-color: #fff2e0;
}
.tr_text {
  color: #000000;
  font-size: 20px;
  font-weight: 900;
}
.terms_list {
  position: relative;
  right: 14px;
  color: #000000 !important;
  line-height: 26px;
  font-weight: 600;
}
.main__container {
  max-width: 1000px;
  margin-inline: auto;
  background: rgb(124, 145, 175);
  padding: 3rem;
}

/* Marquee styles */
.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 1px 0px;
}

.marquee span {
  color: #fe0000;
  font-size: 14px;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite;
}
.marquee span.paused {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.term_border {
  border-top: 2px dashed #000;
}
.f_17 {
  font-size: 17px;
}
.f_15 {
  font-size: 15px;
}
.signed_block {
  border: 1px solid #dfdedd;
  color: #262626;
  width: 156px;
  border-radius: 2px;
  padding: 2px;
  font-size: 9px;
}
.contact_add {
  background-color: #ffba4f;
  color: #000000;
  font-size: 14px;
  font-family: serif;
}
/* maths genious */
.event_vl {
  border-left: 2px solid white;
}
.id_fileds {
  font-family: serif;
  /* bottom: 47px; */
}
.event_id_block .scanner {
  font-size: 13px;
  color: #fe0000;
}
.id_fileds input {
  border: none;
  border-bottom: 1.5px solid black;
  font-size: 19px;
  color: black;
  font-weight: 600;
  background-color: white;
  position: relative;
  bottom: 5px;
  padding-left: 7px;
  word-break: break-all;
  font-family: "EB Garamond";
}
.id_fileds p {
  font-size: 20px;
  color: black;
  font-weight: 300;
  padding-top: 3px;
  font-family: "EB Garamond";
  /* font-family: Garamond, sans-serif; */
  /* font-size: 27px; */
  /* font-weight: 600; */
  font-weight: 550;
}
.id_class input {
  width: 38.5%;
}

.id_fileds .division {
  width: 26%;
}
.id_fileds .event_no {
  width: 24%;
}
.id_fileds .reg_no {
  width: 37.5%;
}
.id_fileds .name {
  width: 72%;
}
.id_fileds .school_name {
  width: 79%;
}
.id_fileds .dob {
  width: 19%;
}
.id_fileds .emil_id {
  width: 45.5%;
  font-size: 17px;
}
.id_fileds .mobile {
  width: 40%;
}
.id_fileds .pincode {
  width: 27%;
}
.id_fileds .slot {
  width: 76%;
}
/* .csp input {
  width: 22%;
} */

.event_instructions {
  font-size: 15px;
  line-height: 25px;
  padding-right: 40px;
}
.event_instructions li {
  padding-left: 5px;
}
.event_instructions .msc {
  font-size: 17px;
}
.evnt_ul {
  list-style-type: square;
  font-size: 19px;
}
.event-register {
  background-color: rgb(233, 236, 239);
  height: 100vh;
}
.event_language-select {
  width: 97px; /* Increase the width of the selector */
  padding: 3px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0; /* Box color */
  color: #333;
  font-size: 16px;
  cursor: pointer;
  outline: none; /* Remove default outline */
}

.event_language-select option {
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  /* border: 2px solid #ccc; */
}
.register-block {
  background-color: white;
  border-radius: 0.5rem;
}
.date_margin div {
  margin-top: 5px !important;
}
.id_fileds .underline {
  flex-grow: 1;
  border-bottom: 1.5px solid black;
  margin-left: 5px;
  word-break: break-all;
}
/* // */
.f_14 {
  font-size: 14px;
}
.event_table_alignment th {
  vertical-align: top;
}
.event_table_alignment td {
  overflow-wrap: break-word;
}
.math_charts_responsive {
  display: flex;
  flex-wrap: wrap;
}

.math_charts_responsive > div {
  /* margin: 10px; */
  flex: 1 1 1%;
}
.class_teacher_btn {
  background-color: #dae0f0;
  font: 1em sans-serif;
  width: 185px;
  padding: 30px 40px;
  border-radius: 5px;
  border: none;
}
.subject_teacher_btn {
  background-color: #f9e3d4;
  font: 1em sans-serif;
  width: 196px;
  padding: 30px 40px;
  border-radius: 5px;
  border: none;
}
.class_report_cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
}
.class_card_view {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 9px;
  margin: 8px;
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.class_card_view.cardOne {
  background-color: #f9af99;
}
.class_card_view.cardTwo {
  background-color: #b7de8b;
}
.class_card_view.cardThree {
  background-color: #bbd5ed;
}
table.teacher_student_report_table {
  width: 100%;
  border-collapse: collapse;
}
.teacher_student_report_table th {
  background-color: #b4c7e7;
  font-size: 15px;
  color: white;
  padding: 5px 7px;
  font-weight: 500;
  border: 1.7px solid white;
}
.teacher_student_report_table td {
  font-size: 14px;
  color: #000000;
  background: #ebf0f9;
  border: 1.7px solid white;
  padding-left: 8px;
}
.teacher_student_report_table tr {
  background: #ebf0f9;
  border-bottom: 1.6px solid white;
}
table.content_question_source_table {
  width: 100%;
  border-collapse: collapse;
}
.content_question_source_table th {
  background-color: #7f7f7f;
  font-size: 15px;
  color: white;
  padding: 5px 7px;
  font-weight: 500;
  border: 1.7px solid white;
}
.content_question_source_table td {
  font-size: 16px;
  color: #000000;
  border: 1.7px solid white;
  padding: 4px 0px 4px 8px;
}
.content_question_source_table .odd {
  background: #e7eaed;
}

.content_question_source_table tr {
  background: #ccd2d8;
  border-bottom: 1.6px solid white;
}
.custom_table_scroll {
  overflow-x: auto;
  overflow-y: hidden;
}
.select_subject_teacher {
  /* width: 140px; */
  padding: 3px;
  border: 1.6px solid #ccc;
  border-radius: 0px;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
.select_subject_teacher option {
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  border-radius: 0px;
  /* border: 2px solid #ccc; */
}
.module_container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.module_cards {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 16px;
  margin: 8px 0px;
  width: 305px;
  height: 150px;
  text-align: center;
  flex: 1;
}
.acc_dashboard {
  /* border-radius: 5px; */
  background-color: #dae3f3;
  /* box-shadow: 0px 0px 4px 0px black; */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-align: center;
  /* background-color: #dae0f0; */
  font: 1em sans-serif;
  width: 170px;
  height: 55px;
  padding: 20px 20px;
  border-radius: 3px;
  border: none;
}
.ab_graph_chart {
  border-radius: 40px;
  background-color: #f5f7fc;
}

.tch_tabs-container {
  width: 100%;
  margin: 0 auto;
}

/* Tab buttons container */
.tch_tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  color: black;
}

/* Individual tab buttons */
.tch_tab-button {
  padding: 5px 25px;
  cursor: pointer;
  background-color: #dfeaf5;
  border: none;
  border-right: 2px solid white;
}

.tch_tab-button:hover {
  background-color: #9cc4e4;
}

/* Active tab styles */
.tch_tab-button.active {
  background-color: #9cc4e4;
}

/* Tab content styles */
.tch_tab-content {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.pariksha_analysis {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 9%;
}
.std_payment_bx {
  background-color: #f2f2f2;
}
.appl_cpn {
  background-color: #239ed0;
}
.py_bx {
  border-bottom: 1px solid #80a7b8;
}
.py_grn_cls {
  color: #12b45b;
}
.py_dis_btn_grn {
  background-color: #12b45b;
}
.apl_text_cl {
  color: #fe0707;
}
.module_list_cards {
  /* display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.5rem; */
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.module_card_view {
  padding: 30px 20px;
  border: 1px solid #0f70cd;
  border-radius: 4px;
  background-color: #e8f3fc;
  min-height: 175px;
  width: 320px;
  /* flex: 1; */
  /* min-width: 200px;
  max-width: 330px; */
}
.module_card_view.cardOne {
  background-color: #f9af99;
}
.module_card_view.cardTwo {
  border-color: #a67c52;
  background-color: #efe3e1;
}
.module_card_view.cardThree {
  border-color: #dd8872;
  background-color: #e2d6d2;
}
.module_card_view .module-title {
  font-size: 42px;
  font-weight: 300;
}
.module_card_view.full .module-title {
  font-size: 52px;
}
.module_card_view h5 {
  font-size: 16px;
  font-weight: 400;
}
.module_card_view.full h5 {
  font-size: 20px;
}
.module_card_view p {
  font-size: 13px;
  font-weight: 500;
}
.test_popup-table-container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  z-index: 100;
  left: 0px;
}
.d_none {
  display: none;
}
.expand_icon {
  left: -26px;
  position: absolute;
  font-size: 13px;
  border: 1.2px solid;
  border-radius: 50%;
  font-weight: normal;
  padding: 0px 1px;
}

#checked_module {
  accent-color: #4caf50;
}
.payment_year-select {
  padding: 3px 6px;
  font-size: 16px;
  border-radius: 4px;
  border: 1.8px solid #ccc;
  cursor: pointer;
}

ul.payment_points {
  font-size: 16px;
  line-height: 1.5em;
  padding: 0;
  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }
}
ul.payment_points {
  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: #ff6600;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }
  }
}
.word_break {
  word-break: break-word;
}
.switch_table {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 21px;
}

.switch_table input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider_table {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_table:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider_table {
  background-color: #2196f3;
}

input:focus + .slider_table {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider_table:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider_table.round {
  border-radius: 34px;
}

.slider_table.round:before {
  border-radius: 50%;
}
.export_btn_select {
  background-color: #0f70cd;
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px 0px;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 17px;
  /* width: 210px; */
}
.export_btn {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.export_btn_select_drop_list {
  position: absolute;
  display: block;
  right: 0;
  max-height: 300px;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.export_btn-list-view {
  background-color: #d8e0e7;
  margin-bottom: 3px;
  padding: 4px 24px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  color: black;
}
.export_btn-list-view:hover,
.export_btn-list-view:focus {
  /* background-color: #2980b9; */
  color: var(--blue-color);
}
.content_cards_list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.content_card {
  display: flex;
  padding: 14px 15px;
  border-radius: 13px;
  background-color: #92d04f;
  /* width: 180px; */
}
.up_value {
  color: #92d04f;
}
.content_img {
  height: 27px;
  width: 27px;
}
.cn_validation_bg {
  background-color: #46b1de;
}
.cn_test_bg {
  background-color: #62cbf3;
}
.test_value {
  color: #62cbf3;
}
.worksheet_value {
  color: #f4c8ac;
}
.cn_worksheet_bg {
  background-color: #f4c8ac;
}
.cn_karakartas_bg {
  background-color: #b8d3ee;
}
.questio_upload_btn {
  background-color: #0f70cd;
  border-radius: 13px;
  padding: 6px 67px;
  color: white;
}
.radius_13 {
  border-radius: 13px;
}
.bg_secondary_light {
  background-color: #f2f2f2;
}
.test_bx_overflow {
  max-height: 80px;
  min-height: 80px;
  overflow-y: auto;
}
.rounded_50 {
  border-radius: 50%;
}
.test_ques_bg {
  background-color: #d5dce5;
}
.select_ques_bg {
  background-color: #afabaa;
}
.easy_bg {
  background-color: #a7d190;
}
.medium_bg {
  background-color: #ffc200;
}
.hard_bg {
  background-color: #fd0000;
}
.questions_add_bx {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 16px;
}
.question_preview_dialog {
  min-width: 58%;
}
.question_view_height {
  height: calc(100vh - 275px);
  overflow-y: auto;
}
.test_cls_blue {
  color: #5434f8;
}
.disabled_bg {
  background-color: #e9eef2 !important;
}
.ch_bg {
  background-color: #eaeef3;
}
.tp_bg {
  background-color: #ffe9f5;
}
.ch_tp_easy_bg {
  background-color: #accd90;
}
.ch_tp_medium_bg {
  background-color: #ffe39e;
}
.ch_tp_hard_bg {
  background-color: #f9cbad;
}
.ch_tp_total_bg {
  background-color: #b4c6ea;
}
.ch_easy_bg {
  background-color: #c7dbb5;
}
.ch_medium_bg {
  background-color: #f6e6bf;
}
.ch_hard_bg {
  background-color: #f3d9c9;
}
.ch_total_bg {
  background-color: #c8d8eb;
}
.tp_easy_bg {
  background-color: #d2e5c0;
}
.tp_medium_bg {
  background-color: #fdf3c1;
}
.tp_hard_bg {
  background-color: #ffe1d5;
}
.tp_total_bg {
  background-color: #dddef5;
}
.border_right_1 {
  border-right: 1px solid #656363;
}
table.content_karyakarta_list_table {
  width: 100%;
  border-collapse: collapse;
}
.content_karyakarta_list_table th {
  background-color: #83cbeb;
  font-size: 15px;
  color: white;
  padding: 5px 7px;
  font-weight: 500;
  border: 1.7px solid white;
}
.content_karyakarta_list_table td {
  background-color: #ebf0f9;
  font-size: 16px;
  color: #000000;
  border: 1.7px solid white;
  padding: 4px 0px 4px 8px;
}
.karya_bx {
  border: 1.7px solid black;
  width: 220px;
  padding: 13px 0px;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}
.gn_karya {
  color: #92d349;
}
.test_karya {
  color: #096d87;
}
.hours_karya {
  color: #fbc700;
}
.text_uline {
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
}
.karya_display_bx {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  justify-content: center;
}
.trapezoid {
  border-bottom: 30px solid #f2f2f2;
  border-left: 37px solid transparent;
  border-right: 40px solid transparent;
  height: 0px;
  width: 125px;
  position: relative;
  left: 30px;
}
.filters_karya {
  background-color: #f2f2f2;
  min-height: 300px;
  border-radius: 27px;
}

.select-dropdown {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.select-dropdown__button {
  padding: 7px 2px 7px 10px;
  background-color: #fff;
  color: black;
  border: 1px solid #e1dada;
  border-radius: 7px;
  cursor: pointer;
  width: 110px;
  text-align: left;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.select-dropdown__button:focus {
  outline: none;
}

.select-dropdown__button .zmdi-chevron-down {
  position: absolute;
  right: 10px;
  top: 25%;
  font-size: 22px;
}

.select-dropdown__list {
  position: absolute;
  left: 5;
  right: 0;
  /* max-height: 300px; */
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  opacity: 0;
  pointer-events: none;
  transform-origin: top left;
  transform: scale(1, 0);
  transition: all ease-in-out 0.3s;
  z-index: 2;
  background-color: #fff;
  /* border: 1px solid #cecece; */
}

.select-dropdown__list.active {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1, 1);
}

.select-dropdown__list-item {
  display: block;
  padding: 5px 12px;
  background: #b8d4f0;
  border-top: 3px solid #e6e6e6;
  font-size: 13px;
  line-height: 1.4;
  cursor: pointer;
  color: black;
  transition: all ease-in-out 0.3s;
  border-radius: 5px;
}

.select-dropdown__list-item:hover {
  background-color: #f5f5f5;
  color: #000;
}
.cn_vl {
  margin-top: 50px;
  border-left: 1px solid #656363;
}
.bg_gray_ligth {
  background-color: #eee;
}
.karya_filter_list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}
.boarder_org_cl {
  border: 2px solid #f6c6ad;
}
table.karyakarta_question_table {
  width: 100%;
  border-collapse: collapse;
}
.karyakarta_question_table th {
  background-color: #156082;
  font-size: 15px;
  color: white;
  padding: 5px 7px;
  font-weight: 500;
  border: 1.7px solid white;
}
.karyakarta_question_table td {
  font-size: 16px;
  color: #000000;
  border: 1.7px solid white;
  padding: 4px 0px 4px 8px;
}
.karyakarta_question_table .odd {
  background: #e7eaed;
}

.karyakarta_question_table tr {
  background: #ccd2d8;
  border-bottom: 1.6px solid white;
}
.karya-table-container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  z-index: 100;
  left: 0px;
}
.content_chart_list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 3rem;
  gap: 3rem;
  justify-content: space-around;
}
.cn_wide-text {
  font-size: 40px;
  font-weight: 700;
}
.cn_karya_txt {
  color: #bad1ea;
}
.bg_btn_up {
  background-color: #156082;
}
.cn_btn_test {
  background-color: #92d050;
}
.cn_btn_wrk {
  background-color: #f88342;
}
.cn_btn_vald {
  background-color: #c00000;
}
.drag_bx {
  height: 38px;
  width: 38px;
  border: 3px solid red;
  border-radius: 19px;
}
.w_100_p {
  width: 100%;
}
.bg_light_blue {
  background-color: rgb(233 240 246);
}
.drop_video {
  background: #afafc9;
  position: absolute;
  bottom: 8px;
  right: 10px;
}
.added_test_bg {
  background-color: #afea9e;
}
.add_test_bg {
  background-color: #ffd858;
}
.level_custom-btn {
  border: none;
  padding: 3px 14px;
  color: white;
}
.level_custom-btn.easy {
  background-color: #4aaa2d;
}
.level_custom-btn.md {
  background-color: #ecbe08;
}
.level_custom-btn.hd {
  background-color: #f8723e;
}
.test_std_text {
  color: #f7753b;
}
.stnd_bg {
  background-color: #96dcf8;
}
