@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #0f70cd;
}

html,
body {
  min-height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  color: #333333 !important;
}

.f12 {
  font-size: 0.75rem;
}

.f18 {
  font-size: 1.25rem;
}

.rounded-12 {
  border-radius: 0.75rem;
}

.rounded-16 {
  border-radius: 1rem;
}

.border-gray {
  border-color: #29abe2 !important;
}

.btn-primary {
  background-color: #0f70cd;
  border-color: #29abe2;
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: #045da5;
  border-color: #29abe2;
}

.border-primary {
  border-color: #0071bc !important;
}

.forgot-color {
  color: #0071bc;
  text-decoration: none;
}

.login-bg {
  background: url("../images/login-bg.png") no-repeat bottom center;
  background-size: cover;
  background-attachment: fixed;
  /* background-color     : #f6f2ef; */
}

.login-block-wraper {
  min-height: calc(100vh - 40px);
  padding: 0 1rem;
}

.login-block {
  box-shadow: 0px 1px 12px #575656 !important;
}

.login-logo {
  width: 180px;
}

.login-desc {
  color: #333333;
}

.login-hd {
  font-size: 1.375rem;
  font-weight: 500;
}

.login-fileds {
  border-top: 1px solid #b3b3b3;
  margin-top: 1rem;
}

.login-fileds .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: 1.125rem;
}

.login-fileds .form-floating > .form-control:focus {
  outline: none !important;
}

.login-fileds .form-control:focus {
  box-shadow: none;
}

.login-fileds .form-floating > label {
  font-size: 18px;
  font-weight: 300;
}

.login-fileds .form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 2.5rem;
}

.inside-bg {
  /* background           : url("../images/inside-bg.jpg") no-repeat bottom center;
	background-size      : cover;
	background-attachment: fixed; */
  background-color: white;
}

.navbar .navbar-toggler {
  border: none !important;
  padding: 0 !important;
  top: 1rem !important;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  background-color: #313a46 !important;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav {
  margin: 0 10px;
}

.sidebar .nav-link {
  font-weight: 300;
  color: #f2f2f2;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active,
.sidebar .nav-link:focus,
.sidebar .nav-link:hover {
  color: #ffffff;
  background-color: #0f70cd;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navbar {
  /* background-color: #313a46; */
  background-color: #f2f2f2 !important;
}

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #313a46 !important;
  text-align: center;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.15rem 1rem;
}

.navbar .form-control:focus {
  box-shadow: none;
}

.logo {
  width: 100px;
  object-fit: contain;
}

.user-name {
  font-weight: 300;
  line-height: 16px;
  position: relative;
}

.user-name:after {
  position: absolute;
  content: "";
  right: 0;
  top: 5px;
  bottom: 5px;
  border-right: 2px solid #333333;
}

.logout-icon {
  background: url("../images/logout-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-indent: -9999px;
  cursor: pointer;
}

.logout-icon:hover,
.logout-icon:focus {
  background-position: 0 -21px;
  transition: 0.4s;
}

.global-search {
  width: 100%;
}

.content-waper {
  padding-top: 1rem;
}

.content-body {
  /* min-height: calc(100vh - 65px); */
  /* min-height: calc(100vh - 225px); */
}

.breadcrumb-hd .breadcrumb-item,
.breadcrumb-hd .breadcrumb-item a {
  font-size: 1.75rem;
  font-weight: 500;
  color: #333333;
  text-decoration: none;
}

.custom-form .form-control,
.custom-form .form-select {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  background-color: transparent;
  padding-left: 0;
  font-weight: 300;
}

.custom-form .form-control:focus {
  box-shadow: none;
}

.custom-form select {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  background: #ffffff url(../images/select-arrow.svg) no-repeat center right
    10px;
  padding-right: 38px;
}

.custom-form select::-ms-expand {
  display: none;
}

.file {
  position: relative;
  overflow: hidden;
}

.file input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.custom-scroll {
  min-height: 0.01%;
  overflow-x: auto;
  border: 1px solid #ddd;
}

.fixed-table-header {
  border: none;
  width: 99.9%;
}

.fixed-table-header th {
  position: sticky;
  top: 0;
  background: #e6e6e6 !important;
  z-index: 2;
  border-bottom: 1px solid #ccc;
  min-width: 100px;
}

.fixed-table-header th:after {
  content: "";
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  background-color: #e6e6e6;
  width: 1px;
}

.individual-user-block {
  margin-top: 100px;
  box-shadow: 0px 1px 12px #575656 !important;
}

.fixed-table-header > thead > tr > th:first-child,
.fixed-table-header > tbody > tr > td:first-child {
  border-left: none;
}

.fixed-table-header > tbody > tr:last-child > td {
  border-bottom: none;
}

.fixed-table-header.table > :not(caption) > * > * {
  background-color: #f2f2f2;
  border-color: #ccc !important;
}

.fixed-table-header.table > thead > :not(caption) > * > * {
  border-top: none;
}

@media (min-width: 768px) {
  /* .login-block-wraper {
		transform: translateY(-100px);
	} */

  .login-block {
    width: 600px;
  }

  .login-elements .login-elements-icons {
    width: 250px;
  }

  .individual-user-login-icons {
    width: 25% !important;
  }

  .individual-user-block {
    width: 1200px;
  }

  .login-fileds {
    border-left: 1px solid #b3b3b3;
    border-top: none;
    margin-top: 0;
  }
}
@media (max-width: 992px) {
  .border_top_forms {
    border-top: 1px solid #0f70cd;
  }
}

@media (min-width: 992px) {
  .nav-side-menu {
    max-height: calc(100vh - 78px) !important;
    overflow: auto;
    font-family: verdana;
    font-size: 12px;
    font-weight: 200;
    background-color: #2e353d;
    position: fixed;
    width: 250px;
    height: 100%;
    color: #e1ffff;
    left: 0px;
    padding-top: 5px;
  }
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }

  .navbar {
    background-color: #f6f2ef;
  }

  .navbar-brand {
    position: relative;
  }

  .navbar-brand:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 10px;
    right: 10px;
    border-bottom: 1px solid #f4715c;
  }

  .sidebar,
  .navbar-brand {
    width: 250px;
  }

  .sidebar {
    padding: 50px 0 0;
  }

  .content-waper {
    padding-left: 265px;
    padding-top: 0;
  }

  .global-search {
    width: 450px;
  }

  .scrollbar {
    /* max-height: calc(100vh - 315px); */
    max-height: calc(100vh - 100px);
  }
}

@media (max-width: 767.98px) {
  .login-row {
    margin: 0;
  }

  .sidebar {
    top: 7rem;
  }
}

.popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000000;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  z-index: 9999;
}

.popup-inner {
  background: white;
  border-radius: 10px;
}

.popup-inner .popup-header {
  padding: 15px 20px;
}

.popup-inner .popup-body {
  padding: 25px 50px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.info-card {
  border-radius: 25px !important;
  border: 1px solid #29abe2;
}

.info-card-1 {
  border-radius: 25px !important;
  border: 1px solid #4992d7;
  /* border          : 1px solid #b3b3b3; */
  /* background-color: #f0f2f2 !important; */
  background-color: #e8f3fc !important;
}

.info-card-2 {
  border-radius: 10px !important;
  /* border             : 1px solid #CDB5A6; */
  /* border             : 1px solid #b3b3b3; */
  /* background-color   : #f0f2f2 !important; */
  background-color: #efe3e1 !important;
  border-color: #a67c52;

  /* flexDirection: "row",
		paddingHorizontal: 20,
		paddingVertical: 10,
		marginVertical: 5.0,
		justifyContent: "space-between",
		alignItems: 'center' */
}

.page-item.active .page-link {
  background-color: #045da5;
  border-color: #29abe2;
}

.page-link {
  cursor: pointer;
}

.disabled-link {
  pointer-events: none;
}

.popup-close {
  border: none !important;
  border-radius: 15px !important;
}

.parent-info {
  padding: 15px 40px !important;
}

.parent-modal-fade {
  overflow-x: none !important;
  overflow-y: hidden !important;
}

.parent-model-dialog {
  /* min-width: 65%; */
  min-width: 75%;
}

.parent-model {
  /* width     : 950px !important; */
  /* min-width : 75%; */
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.questions-model {
  /* width           : 850px !important; */
  background-color: #edeeef !important;
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.questions-model-dialog {
  min-width: 65%;
}

.active-control {
  /* text-decoration: underline; */
  color: #0d6efd !important;
  cursor: pointer;
}

.questions-grid {
  padding-left: 235px !important;
  padding-right: 235px !important;
}

.navbar-brand {
  /* background-color   : white !important; */
  background-color: #f2f2f2 !important;
  border-right: solid 1px #dee2e6;
}

.clear-btn {
  background-color: #313a46;
  color: white;
}

.clear-btn:hover {
  color: white;
}

.ft-14 {
  font-size: 14px;
}

.msb-table {
  display: block;
  max-width: 100vw;
  overflow-x: auto;
}

/* table,
tr,
th,
td {
	border : 1px solid;
	padding: 2px
} */

.report-header {
  height: 50px !important;
}

.report-table {
  border: 1px solid #adb5bd !important;
  padding: 2px !important;
  background-color: white;
  width: 100%;
}

.report-table-row {
  border: 1px solid #adb5bd !important;
  border-collapse: collapse !important;
}

.report-table-row td {
  border: 1px solid #adb5bd !important;
  border-collapse: collapse !important;
}

.report-table-row th {
  width: 500px !important;
}

.report-table-row td {
  width: 500px !important;
}

.report-table-row1 {
  border: 1px solid #adb5bd !important;
  border-collapse: collapse !important;
}

.report-table-row1 td {
  border: 1px solid #adb5bd !important;
  border-collapse: collapse !important;
  width: 500px !important;
}

.react-timerange-picker__wrapper {
  padding: 5px;
  margin-top: 10px;
  border: thin solid rgb(0 0 0 / 13%) !important;
}

.canvasjs-chart-credit {
  display: none;
}

.nav-tabs .nav-link .active {
  margin-bottom: -1px;
  /* background: 0 0; */
  /* border             : 1px solid #adb5bd !important; */
  border-bottom-width: 0px !important;
}

.custom-tabs {
  background-color: #4d4d4d;
}

.custom-tabs .nav-link {
  color: #ffffff;
  border-radius: 0;
  border: none;
  font-size: 0.85rem;

  /* padding   : 0.4rem 1rem; */
}

.custom-tabs .nav-link.active,
.custom-tabs .nav-link:focus,
.custom-tabs .nav-link:hover {
  background-color: #f2f2f2;
  color: #333333;
}

.custom-tabs .nav-item {
  border-right: 1px solid #f2f2f2;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* color           : #495057; */
  /* background-color: #fff; */
  background-color: #f2f2f2;
  color: #333333;
  border-color: #adb5bd #adb5bd #adb5bd !important;
}

.forgot-password {
  transform: translateY(-10px) !important;
}

.eye-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  margin-right: 15px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.modal {
  background: #000000bf;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.timings-view {
  position: absolute;
  top: 20%;
}

.time-range {
  width: 40%;
  display: inline-block;
  margin-right: 30px;
}

.file input {
  cursor: pointer;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

.cursor-pointer {
  cursor: pointer !important;
}

.edit-icon {
  color: #0f70cd;
  padding: 5px;
}

.msb-btn {
  border: none;
  background-color: #0f70cd;
  border-radius: 5px;
  color: white;
}

.actions-view {
  min-width: 100px;
}

.student-upload-dialog {
  min-width: 60%;
}

.student-upload-model {
  /* width           : 850px !important; */
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.custom-dob {
  width: 90px !important;
  display: inline-block !important;
  margin-left: 20px;
}

.dependent-mobile-number {
  width: 115% !important;
}

/* iframe {
  display: none;
} */

.sc-bcCSHH.dwRLlu {
  display: none;
}

.sc-fpYaaq.ieGZQL {
  overflow-x: hidden;
  background: white;
}

.organisation-header-logo {
  margin-right: 15px;
}

.organisation-header {
  border-left: solid 1px #b3b3b3;
  padding-left: 15px;
}

.organisation-left-content {
  color: #333345 !important;
  font-weight: 100 !important;
}

.organisation-right-success-content {
  padding-left: 100px;
}

.org-congrats-text {
  color: #0f70cd;
}

.org-success {
  color: #39b54a;
}

.org-aboard-text {
  font-weight: 400 !important;
}

.org-signup-process {
  font-weight: 400 !important;
  color: #0f70cd;
}

.org-create-text {
  font-size: 12px;
}

.organisation-header h4 {
  font-weight: 300;
}

.academic-checkbox {
  padding-right: 20px;
}

.academic-checkbox-part2 {
  padding-right: 12px;
}

/*latest css */

.rocket-img {
  width: 200px;
  margin-top: 100px;
  transform: rotate(30deg);
  margin-left: 50px;
}

.success-text {
  font-size: 3.75rem;
}

.text-dgray {
  color: #333333;
}

.text-blue {
  color: #0f70cd;
}

.text-green {
  color: #39b54a;
}

.f10 {
  font-size: 0.625rem;
}

.f24 {
  font-size: 1.5rem;
}

.signup-block {
  box-shadow: 0px 1px 12px #575656;
}

.login-elements {
  margin-top: 3rem;
}

.custom-form .required-star {
  font-weight: 700;
}

.required-star {
  font-size: 20px;
  line-height: 20px;
  color: #ff0000;
  font-style: normal;
}

.org-custom-form .form-control {
  background-color: transparent !important;
  font-weight: 300 !important;
  border-color: #cccccc !important;
  font-size: 12px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
}

.org-custom-form .form-select {
  background-color: transparent !important;
  font-weight: 300 !important;
  border-color: #cccccc !important;
  font-size: 12px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
}

.org-custom-form .form-control {
  padding: 0.375rem 0.75rem !important;
}

.org-custom-form .form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

.org-custom-form .form-select {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  background: url("../images/new-select-arrow.svg") no-repeat center right 10px !important;
  padding-right: 38px !important;
  padding-left: 10px !important;
}

.form-check-inline-custom .form-check-label {
  font-size: 14px;
}

.org-custom-form .form-label {
  font-size: 14px;
  /* margin-left: 15px; */
}

.rounded-org-12 {
  border-radius: 0.5rem;
}

.signup-quot {
  font-size: 36px;
}

@media (min-width: 992px) {
  .signup-quotblock {
    border-right: 1px solid #cccccc;
  }
}

@media (min-width: 1200px) {
  .signup-block {
    margin-top: 100px;
  }
}

.error-msg {
  font-size: 12px;
  color: #ff0000;
  margin-top: 6px;
  margin-bottom: 0;
  padding-left: 15px;
}

.academic-checkbox {
  margin-right: -28px;
}

.disabled-link {
  pointer-events: none;
  color: #62a1fe;
  text-decoration: none !important;
}

/* breadcum*/

.first ol {
  background-color: rgb(63, 53, 65) !important;
  background-color: #eee !important;
}

.first a:hover {
  /* color: #ffffff !important; */
  color: #01447e;
  /* text-decoration: underline !important; */
  cursor: pointer;
}

.active-1 {
  /* color: #ffffff !important; */
  color: black !important;
}

.breadcrumb > li + li:before {
  content: "" !important;
  text-decoration: none !important;
}

.breadcrumb {
  /* padding       : 8px; */
  padding: 10px 16px;
  font-size: 14px;
  /* color: #aaa !important; */
  letter-spacing: 2px;
  border-radius: 5px !important;
}

/* .fa,
i {
	color    : rgb(0, 183, 255) !important;
	font-size: 10px
} */

.breadcum-arrow {
  color: #333 !important;
  font-size: 20px;
  padding-left: 10px;
}

.first {
  background-color: white !important;
}

.breadcum-label {
  margin-top: 15px !important;
}

/* a {
	text-decoration: none !important;
	color: #aaa !important
}

a:focus,
a:active {
	outline: none !important;
	box-shadow: none !important
} */

.fa-caret-right {
  vertical-align: middle;
  font-size: 20px !important;
}

.breadcrumb-item a {
  text-decoration: none !important;
  color: #0071bc;
}

.breadcrumb-item a:focus,
a:active {
  outline: none !important;
  box-shadow: none !important;
}

.breadcum-active-label {
  padding-top: 6px !important;
}

/* .breadcrumb-item+.breadcrumb-item {
	padding-top: 5px !important;
} */

@media (max-width: 767px) {
  .breadcrumb {
    font-size: 10px;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }

  /* .fa {
		font-size: 9px !important
	} */

  .breadcrumb {
    letter-spacing: 1px !important;
  }

  .fa-caret-right:before {
    content: "\f0da";
    vertical-align: super !important;
  }
}

.maths-pay {
  border: none;
  background-color: red;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
}

.maths-title {
  font-size: 3rem !important;
}

.start-now-btn {
  background-color: #0d6efd;
  border: none;
  padding: 5px 60px;
  color: white;
  border-radius: 10px;
  font-size: 28px;
}

.level-status {
  color: #0d6efd;
  font-size: 42px;
  font-weight: bold;
}

.circle {
  border: 0.1em solid #cdb5a6;
  border-radius: 100%;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  display: inline-block;
  margin: 2px;
  padding-top: 3px;
}

.circle p {
  font-size: 0.7em;
  font-weight: bold;
  color: black;
}

.worksheet-circle {
  padding: 1em;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 1;
  border: solid 1px gray;
  margin: 5px;
  cursor: pointer;
}

.level-tag {
  padding-top: 2rem;
}

.level-score {
  font-size: 50px;
  color: #39b54a;
  font-weight: bold;
}

.level-test {
  font-size: 38px;
  font-weight: bold;
}

.assign-user-dialog {
  /* min-width: 65%; */
  min-width: 50%;
}

.mla-ability-btn {
  /* border-color    : #a67c52; */
  border: 1px solid #cccccc;
  /* background-color: #efe3e1; */
  background-color: #e3e3e3;
  border-radius: 5px;
  color: black;
  padding: 2px 10px;
  margin-right: 10px;
}

.worksheet-cards {
  border: none !important;
}

.mla-reports-model-dialog {
  min-width: 50%;
}

.ability-topic-header {
  background: #ccc !important;
  color: black;
  font-weight: 700;
}
.questions-valid-header {
  background: #fbe5d6 !important;
  color: black;
  font-weight: 700;
}
.questions-valid-header th {
  padding: 16px 0px;
}
.questions-valid-table > :not(:last-child) > :last-child > * {
  border-bottom-color: #ccc !important;
}
.questions-valid-table th {
  border-right: 1px solid #ccc !important;
}
.questions-valid-table td {
  border-right: 1px solid #ccc !important;
}

.ability-table > :not(:last-child) > :last-child > * {
  border-bottom-color: #ccc !important;
}

.topic-result-model {
  border-radius: 15px;
}

.topic-result-model-btn {
  background-color: red;
  color: white;
}

.mns-card-info {
  padding-bottom: 30px !important;
}

.ability-model {
  min-width: 50%;
}

.worksheet-model-dialog {
  min-width: 40%;
}

.worksheet-model {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.worksheet-quesions-dialog {
  min-width: 75%;
}
.quesions-upload_dialog {
  min-width: 50%;
}
.worksheet-questions-model {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.subscription-payment-dialog {
  min-width: 50%;
}

.worksheet-model {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.assign-student-body {
  display: block;
  height: 250px;
  overflow: auto;
}

.assign-student-head,
.assign-student-body tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.subscription-student-body {
  display: block;
  height: 450px;
  overflow: auto;
}

.subscription-student-head,
.subscription-student-body tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.math-tool__input {
  background: #fcf;
  padding: 1em;
}

.math-tool__textarea {
  border: 0;
  box-sizing: border-box;
  font: inherit;
  min-height: 12em;
  padding: 0.5em;
  width: 100%;
}

.math-tool__output {
  background: #cfc;
  /* overflow: auto; */
  padding: 1em;
}

/* MathJax customizations */
/* ---------------------- */

.MathJax_Preview {
  display: none;
}

.cdk-question-view {
  border-bottom: solid 1px gray;
}

.questions-save-btn {
  /* border-top: solid 1px gray; */
  padding: 15px 0px;
  float: right;
}

.nav-link:focus,
.nav-link:hover {
  color: #fff;
}

.reports-show {
  display: block !important;
}

/* .nav-side-menu {
	max-height         : calc( 100vh - 65px ) !important;
	overflow           : auto;
	font-family        : verdana;
	font-size          : 12px;
	font-weight        : 200;
	background-color   : #2e353d;
	position           : fixed;
	width              : 250px;
	height             : 100%;
	color              : #e1ffff;
	left               : 0px;
	padding-top        : 5px;
} */

.nav-side-menu .brand {
  background-color: #313a46;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}

.nav-side-menu .toggle-btn {
  display: none;
}

.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 30px;
  cursor: pointer;
}

.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}

.nav-side-menu ul .active,
.nav-side-menu li .active {
  /* border-left     : 3px solid #d19b3d; */
  background-color: #0f70cd;
  padding: 0.25rem 1rem;
  color: #fff;
  display: block;
  /* font-weight     : 300; */
  font-size: 16px;
}

.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  /* color: #d19b3d; */
  color: white;
  background-color: #0f70cd;
}

.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  /* color: #d19b3d; */
  color: white;
  background-color: #0f70cd;
}

.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #313a46;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}

.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color: #313a46;
}

.nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
  /* font-family      : FontAwesome; */
  /* content       : "\f105"; */
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.nav-side-menu li {
  padding-left: 0px;
  /* border-left  : 3px solid #2e353d; */
  /* border-bottom: 1px solid #23282e; */
}

.nav-side-menu li a {
  text-decoration: none;
  color: #f2f2f2;
  padding-left: 1rem;
  font-size: 16px;
}

.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}

.nav-side-menu li:hover {
  /* border-left       : 3px solid #d19b3d; */
  background-color: #0f70cd;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

@media (max-width: 767px) {
  .nav-side-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    background-color: #2e353d;
  }

  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }

  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}

@media (min-width: 767px) {
  .nav-side-menu {
    background-color: #2e353d;
  }
}

.mobile-login-logo {
  width: 150px;
}

.mobile-back-icon {
  width: 25px;
  cursor: pointer;
}

.mobile-back-icon-header {
  text-align: end;
  padding-top: 10px;
}

.mobile-modal-footer {
  border-top: none !important;
}

.mobile-modal-header {
  border-bottom: none !important;
  padding: 10px !important;
}

.mobile-modal-content {
  border-radius: 10px !important;
}

mjx-math {
  white-space: none !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

mjx-math {
  white-space: normal !important;
}

.scrolling_nav {
  display: inline-flex !important;
  width: 100% !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  height: 20% !important;
  flex-wrap: nowrap !important;
}

.accordion {
  background-color: white;
  color: #444;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  /* border-bottom   : solid 1px #ccc; */
}
.test,
.accordion:hover {
  background-color: #f2f2f2;
}
.accordion:after {
  /* content            : "\032C"; */
  /* content            : "\f107"; */
  font-family: "Font Awesome 5 Free";
  /* color           : white; */
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 15px;
  background-color: #efefef;
  height: 25px;
  padding-top: 3px;
  padding-right: 10px;
  padding-bottom: 15px;
  padding-left: 10px;
  border-radius: 5px;
}
.test:after {
  /* content: "\2303"; */
  /* content         : "\f106"; */

  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 15px;
  background-color: #efefef;
  height: 25px;
  padding-top: 3px;
  padding-right: 10px;
  padding-bottom: 15px;
  padding-left: 10px;
  border-radius: 5px;
}

.panel {
  padding: 0 18px;
  background-color: white !important;
  max-height: 0;
  /* overflow              : hidden; */
  overflow: auto;
  transition: max-height 0.5s ease-in-out !important;
  max-width: 700px !important;
}

.showTab {
  display: table-row;
  transition: max-height 0.2s ease-in;
  background-color: white !important;
}

.hideTab {
  transition: max-height 0.2s ease-out;
  display: none;
}

.bg_child td {
  background-color: white !important;
  transition: 0.2s ease-in-out !important;
}

.tableFixHead thead th {
  position: sticky !important;
  top: 0px !important;
}

.tableFixHead {
  overflow-y: auto;
  height: 400px;
}
input[type="number"].no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled_bg {
  background-color: hsl(0, 0%, 96.5%);
}
.z_index_multiselect {
  /* z-index: 999; */
}

.dropdown-content {
  z-index: 1093 !important;
}
.wrs_tickContainer {
  display: none !important;
}
.fw_bold {
  font-weight: bold;
}
.nav-test-tabs .nav-item.show .nav-link,
.nav-test-tabs .nav-link.active {
  background-color: #f2f2f2;
  color: #333333;
  border-color: #adb5bd #adb5bd #adb5bd !important;
  font-size: 0.85rem;
}
.nav-test-tabs .nav-link {
  margin-bottom: -1px;
  background-color: #4d4d4d;
  color: #f2f2f2;
  border-bottom-width: 0px !important;
  font-size: 0.85rem;
}

/* ckeditor */
.custom-image {
  max-width: 100%; /* Adjust the maximum width as needed */
  height: auto; /* Maintain aspect ratio */
  border: 2px solid #ccc; /* Add a border */
  margin: 10px 0; /* Add margin for spacing */
}
